import {adultContentImagePath} from "@shared/configs";
import "lazysizes";
import React from "react";
import {Link} from "react-router-dom";
import Price from "../ProductPage/Price";
import VIPLimitedTag from "../VIPLimitedTag";
import WishlistButton from "../WishlistButton/index";
import SmallProductTag from "./SmallProductTag";

export default function SmallProductCard({product, storeName, adultContentPermission, categoryName}) {
  const checkedImageUrl = product.is_adult && !adultContentPermission ? adultContentImagePath : product.imgUrl;
  // Check if out of stock
  const isInStock = product.type_id === "grouped" ? product.is_in_stock : product.is_in_stock && product.stock > 0;
  const outOfStockClass = isInStock ? "" : "-outOfStock";
  return (
    <div className="ur-product-card-small" role="presentation">
      <Link
        to={{
          pathname: `/${storeName}/product/${product.id}`,
          state: {
            subCategory: categoryName,
          },
        }}
      >
        <div className={`ur-product-card-small__img${outOfStockClass}`}>
          <img alt={product.name} data-src={checkedImageUrl} className="lazyload" />
          <WishlistButton product={product} type="productCard" />
        </div>
        <div className="row middle-xs ur-product-card-small__details">
          {product.isVIPLimited && <VIPLimitedTag />}
          {product.type_id === "grouped" && <SmallProductTag text="任選專區" />}
          <p className="ur-product-card-small__name">{product.name}</p>
        </div>
        <Price
          originalPrice={product.originalPrice}
          specialPrice={product.specialPrice}
          isVIPLimited={product.isVIPLimited}
          hideVIPPrice={product.hideVIPPrice}
          breakPrice
          disableMargin
        />
      </Link>
      {product.discount !== -1 && <p className="ur-product-card-small__discount">{`${product.discount}折`}</p>}
    </div>
  );
}
