// @flow

import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import iterableAnalytics from "@shared/iterableAnalytics";
import Searchbar from "../Search/SearchBar";
import SearchTextsSection from "../Search/SearchTextsSection";

type Props = {
  storeName: string,
  searchRecommands: Array<string>,
  totalQtyCountInCart: number,
  history: object,
  isChekoutPage: boolean,
};

class DesktopPageHeader extends React.Component<Props> {
  constructor(props) {
    super(props);
    this.state = {
      searchString: "",
    };
  }

  goSearch = (event) => {
    event.preventDefault();
    const {history, storeName} = this.props;
    const {searchString} = this.state;

    iterableAnalytics.search(searchString);

    if (storeName !== "proteinshop" && storeName !== "morningshop" && storeName !== "default") {
      history.push({
        pathname: `/default/search`,
        search: `?q=${searchString}`,
      });
    } else {
      history.push({
        pathname: `/${storeName}/search`,
        search: `?q=${searchString}`,
      });
    }
  };

  setSearchString = (event) => {
    this.setState({
      searchString: event.target.value,
    });
  };

  render() {
    const {storeName, searchRecommands, totalQtyCountInCart, history, isChekoutPage} = this.props;

    let isDefault = storeName === "default" ? "__selected" : "";
    const isMorning = storeName === "morningshop" ? "__selected" : "";
    const isProtein = storeName === "proteinshop" ? "__selected" : "";

    if (!storeName) {
      isDefault = "__selected";
    }
    if (isChekoutPage) {
      return (
        <nav className={`ur-page-header-desktop ${storeName || "default"}-backgroundcolor`} id="desktop-header">
          <div className="ur-page-header-desktop__wrapper row middle-xs">
            <h1 className="col-xs-1 ur-page-header-desktop__logo">
              <Link to="/default">UrMart</Link>
            </h1>
            <div className="col-xs-3 col-xs-offset-8 row end-xs">
              <Link to="/cart" className="col-xs-2 ur-page-header-desktop__icon">
                <i className="material-icons">shopping_cart</i>
                {totalQtyCountInCart > 0 && <div className="ur-page-header-desktop__cartqty">{totalQtyCountInCart}</div>}
              </Link>
            </div>
          </div>
        </nav>
      );
    }
    return (
      <header className={`ur-page-header-desktop ${storeName || "default"}-backgroundcolor`} id="desktop-header">
        <nav className="ur-page-header-desktop__wrapper row middle-xs">
          <h1 className="col-xs-1 ur-page-header-desktop__logo">
            <Link to="/default">UrMart</Link>
          </h1>
          <div className="col-xs-3 row middle-xs ur-page-header-desktop__store">
            <div className={`ur-page-header-desktop__store-item${isDefault} col-xs-4`}>
              <Link to="/default">優馬選品</Link>
            </div>
            <div className={`ur-page-header-desktop__store-item${isMorning} col-xs-4`}>
              <Link to="/morningshop">早餐吃麥片</Link>
            </div>
            <div className={`ur-page-header-desktop__store-item${isProtein} col-xs-4`}>
              <Link to="/proteinshop">運動吃蛋白</Link>
            </div>
          </div>
          <div className="col-xs-5">
            <Searchbar
              storeName={storeName || "default"}
              history={history}
              className=""
              style={{paddingTop: "0.5rem"}}
              search={this.goSearch}
              setString={this.setSearchString}
            />
            {"推薦搜尋:" && <SearchTextsSection searchTextsData={searchRecommands} storeName={storeName} type="index" />}
          </div>
          <div className="col-xs-3 row end-xs">
            <Link to="/wishlist" className="col-xs-2 ur-page-header-desktop__icon">
              <i className="material-icons">favorite</i>
            </Link>
            <Link to="/customer" className="col-xs-2 ur-page-header-desktop__icon">
              <i className="material-icons">person</i>
            </Link>
            <Link to="/cart" className="col-xs-2 ur-page-header-desktop__icon">
              <i className="material-icons">shopping_cart</i>
              {totalQtyCountInCart > 0 && <div className="ur-page-header-desktop__cartqty">{totalQtyCountInCart}</div>}
            </Link>
          </div>
        </nav>
      </header>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    searchRecommands: state.appConfig.search_recommend,
    totalQtyCountInCart: state.cart.totalQtyCountInCart,
  };
};

export default connect(mapStateToProps)(DesktopPageHeader);
