import Cookies from "js-cookie";
import queryString from "query-string";
import {ECID_KEY, SBID_KEY} from "./storageKeyName";
/**
 * Other funtion the processing local data
 */
export function EcommerceId() {
  const ecidKey = ECID_KEY;
  const sbidKey = SBID_KEY;
  const ecid = Cookies.get(ecidKey);
  const sbid = Cookies.get(sbidKey);

  return {
    getEcid: () => {
      return ecid;
    },
    getSbid: () => {
      return sbid;
    },
    setEcid: (value) => {
      if (!value) {
        return;
      }
      if (ecid === value) {
        return;
      }
      try {
        Cookies.set(ecidKey, value, {
          path: "/",
          expires: 1, // expire after one day
        });
      } catch (error) {}
    },
    setSbid: (value) => {
      if (!value) {
        return;
      }
      if (sbid === value) {
        return;
      }
      try {
        Cookies.set(sbidKey, value, {
          path: "/",
          expires: 1, // expire after one day
        });
      } catch (error) {}
    },
  };
}

/**
 * Set ecommerence Id
 */
export function setEcommerceId(locationSearch) {
  const params = queryString.parse(locationSearch);
  const ecom = new EcommerceId();

  ecom.setEcid(params.ecid);
  ecom.setSbid(params.sbid);
}
