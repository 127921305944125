import React from "react";
import Searchbar from "../Search/SearchBar";
import MessagerIcon from "./MessagerIcon";

export default function CategoryHeader(props) {
  const {history, match} = props;
  return (
    <>
      <Searchbar history={history} storeName={match.params.storeName} className="col-xs-9" />
      <div className="col-xs-2">
        <MessagerIcon className="ur-page-header__icon right" color="white" />
      </div>
    </>
  );
}
