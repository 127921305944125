import React, {Component} from "react";
import {connect} from "react-redux";
import {clearMessage} from "../../actions/global";
import CartAdd from "./CartAdd";
import CartError from "./CartError";

class Message extends Component {
  componentDidUpdate(prevProps) {
    const {showMessage, showActionButton} = this.props;
    if (prevProps.showMessage !== showMessage && showMessage && !showActionButton) {
      this.startTimter();
    }
  }

  startTimter = () => {
    // setTimeInterver
    const {clear} = this.props;

    this.urMessageTimer = setTimeout(() => {
      clear();
      clearTimeout(this.urMessageTimer);
    }, 2500);
  };

  clearMessage = () => {
    const {clear} = this.props;
    clear();
  };

  reload = () => {
    window.location.reload();
  };

  render() {
    const {showMessage, message, type, showActionButton} = this.props;
    let messageContent = <i className="material-icons">error</i>;

    if (showMessage) {
      switch (type) {
        case "cart-error":
          messageContent = <CartError itemName={message} />;
          break;
        case "cart-add":
          messageContent = <CartAdd itemName={message} />;
          break;
        default:
          messageContent = <p>{message}</p>;
          break;
      }
      return (
        <div className="ur-message-popup">
          <i className="material-icons" onClick={this.clearMessage} style={{position: "absolute", top: 5, right: 5}}>
            close
          </i>
          <div className="ur-message-popup__content">{messageContent}</div>
          <div className="ur-message-popup__close" onClick={this.clearMessage} />
          {showActionButton && (
            <button onClick={this.reload} style={{margin: "8px", textAlign: "center"}}>
              請點此重試
            </button>
          )}
        </div>
      );
    }

    return <React.Fragment />;
  }
}

const mapStateToProps = (state) => {
  return {
    message: state.global.message,
    type: state.global.messageType,
    showMessage: state.global.showMessage,
    showActionButton: state.global.showActionButton,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    clear: () => {
      dispatch(clearMessage());
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Message);
