const APIEndpoints = {
  tokenExchange: "/customermanager/exchange",
  login: "/customermanager/login",
  order: "/orders", // 包含訂單列表, 取得最新訂單資訊, 取消訂單
  socialLogin: "/customermanager/social/login",
  chekcoutConfig: "/checkout/configData",
  cartId: "/carts/mine",
  shippingInfo: "/carts/mine/shipping-information",
  paymentInfo: "/carts/mine/payment-information",
  cart: "/carts/mine/items", // 包含加入, 修改, 刪除購物車
  register: "/customermanager/register",
  updateCustomerInfo: "/customermanager/updateUserBasicInfo",
  otpSend: "/themecafe_otpverification/frontend/otpsend",
  otpVerifyed: "/themecafe_otpverification/frontend/otpveriy",
  forgetPassword: "/customer/forgetpassword/verify",
  resetPassword: "/customer/forgetpassword/reset",
  verifyResetPasswordAcoouont: "/customers/password",
};

export const customerEndPoints = [
  APIEndpoints.forgetPassword,
  APIEndpoints.resetPassword,
  APIEndpoints.login,
  APIEndpoints.otpSend,
  APIEndpoints.otpVerifyed,
  APIEndpoints.verifyResetPasswordAcoouont,
];

export const cartEndPoints = [APIEndpoints.cart, APIEndpoints.cartId, APIEndpoints.chekcoutConfig];
export default APIEndpoints;
