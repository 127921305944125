import {getBackgroundColor} from "@shared/appHelpers";
import React from "react";
import {CATEGORY, COUPON, INDEX, MODAL, PRODUCT} from "../../types/typeConstants";
import CategoryHeader from "./CategoryHeader";
import CouponHeader from "./CouponHeader";
import DefaultHedaer from "./DefaultHeader";
import IndexHeader from "./IndexHeader";
import ModalHeader from "./ModalHeader";
import ProductHeader from "./ProductHeader";

export default function MobileHeader(props) {
  const {match, type, history, clickHandler, title} = props;
  const backgroundColor = getBackgroundColor(match.params.storeName);
  let dynamicContent = null;

  switch (type) {
    case INDEX:
      dynamicContent = <IndexHeader match={match} backgroundColor={backgroundColor} />;
      break;
    case CATEGORY:
      dynamicContent = <CategoryHeader history={history} match={match} />;
      break;
    case PRODUCT:
      dynamicContent = <ProductHeader match={match} />;
      break;
    case MODAL:
      dynamicContent = <ModalHeader title={title} clickHandler={clickHandler} />;
      break;
    case COUPON:
      dynamicContent = <CouponHeader title={title} clickHandler={clickHandler} />;
      break;
    default:
      dynamicContent = <DefaultHedaer title={title} match={match} />;
      break;
  }
  return (
    <header className="ur-page-header row middle-xs" style={{backgroundColor}}>
      {dynamicContent}
    </header>
  );
}
