export const apiHost = process.env.NODE_ENV === "development" ? "" : process.env.REACT_APP_API_URI;
export const fbId = process.env.REACT_APP_FB_ID;
export const bearerToken = process.env.REACT_APP_TOKEN;
export const s3Host = "https://img.fastretain.com/catalog/product";
export const s3HostWithCache = "https://img.fastretain.com/catalog/product/cache/small_image/270x270/beff4985b56e3afdbeabfc89641a4582";
export const s3Assets = "https://urmart.s3-ap-northeast-1.amazonaws.com/WebAssets";
export const s3Icon = "https://s3-ap-northeast-1.amazonaws.com/urmart/catalog/category/icon";
export const s3Origin = "https://img.fastretain.com/catalog/product";
/**
 * cvs redirect setting
 */
export const cvsCommunication = {
  idForHost: "urmartWebApp",
  emapHost: process.env.REACT_APP_EMAPHOST,
};

export const store_id = 1;
export const website_id = 1;

/** Tappay 設定參數 */
export const tappayEnv = process.env.REACT_APP_TAPPAY_ENV;
export const tappayId = process.env.REACT_APP_TAPPAY_ID;
export const tappayKey = process.env.REACT_APP_TAPPAY_KEY;
/** App 名稱 & 版本設定 */
export const appName = process.env.REACT_APP_NAME;
export const appVersion = process.env.APP_VERSION; // package.json version

export const adultContentImagePath = "https://urmart.s3-ap-northeast-1.amazonaws.com/18restriction.png";
export const defaultMetaImage = "https://urmart.s3-ap-northeast-1.amazonaws.com/WebAssets/urmart-banner.png";

export const mediaQueryMdScreen = "(min-width: 992px)";
export const linTagId = process.env.REACT_APP_LINE_TAG_ID;

// 暫時不用根據開發環境代換 hostname, 因為無論是測試站還是正式站 host name 都是 urmart.com
export const currentHost = "urmart.com";

// coupon 頁問號要去的連結
export const couponHelpPageLink =
  "https://urmart.zendesk.com/hc/zh-tw/articles/360037218654--%E6%8A%98%E5%83%B9%E5%88%B8%E8%A6%81%E5%A6%82%E4%BD%95%E4%BD%BF%E7%94%A8-";
export const liveHelpLink = "https://urmart.zendesk.com/hc/zh-tw";
export const websitePublicURL = process.env.PUBLIC_URL;
export const VIPCardSku = "URMARTVIP-BESTPRICE";
export const VIPCardId = 6242;
export const vipLimitedCategoryId = process.env.REACT_APP_STAGE === "production" ? 38429 : 37406;
export const vipLimitedCategoryIcon = `${s3Assets}/vip_limited_category_icon.png`;
export const limitPromotionId = process.env.REACT_APP_STAGE === "production" ? 37223 : 37313;
export const brandsWithHideVIP = ["320", "319", "1158", "986", "883", "1299", "1175", "1176", "972"];
export const iterableAPIKey = process.env.REACT_APP_ITERABLE_KEY;

export const lineTrackingDomain =
  process.env.REACT_APP_STAGE === "production" ? "https://buy-order-api.line.me" : "https://buy-order-api-ec-sandbox.line.me";
export const lineAuthKey = process.env.REACT_APP_LINE_AUTHKEY;
