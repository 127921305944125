/* eslint-disable camelcase */
/* eslint-disable no-underscore-dangle */
import getUTMParams from "@shared/getUTMParams";
import {getVendorOfShipping} from "@shared/productUtils";
import cacheProdcutFields, {getCachedProductFields} from "@storage/cacheProdcutInfo";
import {BrowserStorage} from "@storage/storage";
import {appVersion, iterableAPIKey, VIPCardSku} from "./configs";

const storage = new BrowserStorage();

const registerBrowserToken = (email) => {
  try {
    const browserToken = sessionStorage.getItem("browser-token");
    if (browserToken) {
      const dataField = {
        browserTokens: [browserToken],
      };
      window._iaq.push(["identify", email, dataField]);
    }
  } catch (error) {
    // handle error
  }
};

const iterableAnalytics = {
  init: () => {
    window._iaq.push(["account", iterableAPIKey]);
  },

  setUser: (customer) => {
    const groupId = customer.groupId || customer.group_id;
    const dataField = {
      Customer_Id: customer.id,
      Last_Name: customer.lastName,
      First_Name: customer.firstName,
      Device: "web",
      phone: customer.custom_attributes[0].value,
      Customer_Group: groupId === 4 ? "Vip" : "Normal",
    };
    window._iaq.push(["identify", customer.email, dataField]);
    registerBrowserToken(customer.email); // 登入後才會把browserToken 綁user
  },
  visit: (landingUrl, metaTitle = "UrMart 優馬選品") => {
    const {utmSource, utmMedium, utmContent, utmCampaign} = getUTMParams();

    const dataField = {
      Landing_Url: landingUrl,
      Meta_Title: metaTitle,
      UtmCampaign: utmCampaign,
      UtmContent: utmContent,
      UtmMedium: utmMedium,
      UtmSource: utmSource,
      Device: "web",
      Web_Version: appVersion,
    };

    window._iaq.push(["track", "Visit", dataField]);
  },
  viewProduct: (productInfo) => {
    const uCardPrice = productInfo.sku === "URMARTVIP-BESTPRICE" ? 1500 : Math.round(productInfo.specialPrice * 0.83);
    const reviewList =
      productInfo.review &&
      productInfo.review.reviews.map((rv) => ({
        Review_Id: rv.review_id,
        Review_Create_Time: rv.created_at,
        Review_Score: parseInt(rv.rating_votes[0].percent, 10) / 20,
        Review_Detail: rv.detail,
      }));
    const dataField = {
      Product_Id: productInfo.id,
      Sku: productInfo.sku,
      Product_Name: productInfo.name,
      Product_Image_Url: productInfo.imgUrl,
      Product_Mode: productInfo.vendorofshipping === "47" ? "Transfer" : "Buyout/Consignment",
      Product_Main_Category: productInfo.mainCategory || "優馬選品",
      Product_Sub_Category: productInfo.subCategory || "",
      Product_Url: document.location.href,
      Stock: productInfo.stock,
      IsOnSale: productInfo.discount > 0,
      OnSale_Expiration_Time: productInfo.discount > 0 ? toISODate(productInfo.special_to_date) : undefined,
      Currency: "TWD",
      Unit_Price: productInfo.originalPrice,
      Unit_Sale_Price: productInfo.specialPrice,
      UCard_Price: uCardPrice,
      Product_Wishlist_Count: productInfo.wishCount,
      Product_Review_Score: productInfo.ratingScore,
      Product_Review_Count: productInfo.review && productInfo.review.reviewsLength,
      Product_Reviews: reviewList,
    };
    // cache product info
    cacheProdcutFields(productInfo.id, {
      reviewCount: reviewList.length,
      reviewList,
      score: productInfo.ratingScore,
      wishCount: productInfo.wishCount,
      mainCategory: productInfo.mainCategory,
      subCategory: productInfo.subCategory,
      shopName: productInfo.mainCategory,
      productIsOnSale: productInfo.discount > 0,
      specialToDate: toISODate(productInfo.special_to_date),
      unitPrice: productInfo.originalPrice,
    });
    window._iaq.push(["track", "ViewProduct", dataField]);
  },
  search: (value) => {
    const stringArray = value.trim().split(" ");
    const dataField = {
      SearchKeys: stringArray,
    };
    window._iaq.push(["track", "Search", dataField]);
  },
  recommendSearch: (value) => {
    const dataField = {
      SearchKeys: [value],
    };
    window._iaq.push(["track", "RecommendSearch", dataField]);
  },
  addToWishList: (productInfo) => {
    const uCardPrice = productInfo.sku === "URMARTVIP-BESTPRICE" ? 1500 : Math.round(productInfo.specialPrice * 0.83);
    const cachedProduct = getCachedProductFields(productInfo.id);
    const dataField = {
      Product_Id: productInfo.id,
      Sku: productInfo.sku,
      Product_Name: productInfo.name,
      Product_Image_Url: productInfo.imgUrl,
      Product_Url: document.location.href,
      Product_Mode: productInfo.vendorofshipping === "47" ? "Transfer" : "Buyout/Consignment",
      Stock: productInfo.stock,
      IsOnSale: productInfo.discount > 0,
      OnSale_Expiration_Time: productInfo.discount > 0 ? toISODate(productInfo.special_to_date) : undefined,
      Currency: "TWD",
      Unit_Price: productInfo.originalPrice,
      Unit_Sale_Price: productInfo.specialPrice,
      UCard_Price: uCardPrice,
      Product_Wishlist_count: cachedProduct.wishCount,
      Product_Review_Score: cachedProduct.ratingScore,
      Product_Review_Count: cachedProduct.reviewCount,
      Product_Reviews: cachedProduct.reviewList,
      Product_Main_Category: cachedProduct.mainCategory || "優馬選品",
      Product_Sub_Category: cachedProduct.subCategory || "",
    };
    window._iaq.push(["track", "AddToWishlist", dataField]);
  },
  viewWishList: (wishListItems, vendorOfShipping) => {
    const dataFieldItems = wishListItems.map((item) => {
      const uCardPrice = item.sku === "URMARTVIP-BESTPRICE" ? 1500 : Math.round(item.specialPrice * 0.83);
      const cachedProduct = getCachedProductFields(item.id);
      const productMode = getVendorOfShipping(item.vendorofshipping, vendorOfShipping);
      return {
        Product_Id: item.id,
        Sku: item.sku,
        Product_Name: item.name,
        Product_Image_Url: item.imgUrl,
        Product_Url: document.location.href,
        Stock: item.stock,
        IsOnSale: item.discount > 0,
        OnSale_Expiration_Time: item.discount > 0 ? toISODate(item.specialToDate) : undefined,
        Currency: "TWD",
        Unit_Price: item.originalPrice,
        Unit_Sale_Price: item.specialPrice,
        UCard_Price: uCardPrice,
        Product_Wishlist_count: item.wishCount,
        Product_Review_Score: cachedProduct.ratingScore,
        Product_Review_Count: cachedProduct.reviewCount,
        Product_Reviews: cachedProduct.reviewList,
        Product_Main_Category: cachedProduct.mainCategory || "優馬選品",
        Product_Sub_Category: cachedProduct.subCategory || "",
        Product_Mode: productMode === "transfer" ? "Transfer" : "Buyout/Consignment",
      };
    });
    const dataField = {
      WishlistItems: dataFieldItems,
    };
    window._iaq.push(["track", "Wishlist", dataField]);
  },
  addToCart: (items, subtotal) => {
    const itemArray = items.map((item) => {
      // try get item from cached
      const cachedProduct = getCachedProductFields(item.product_id);
      return {
        id: item.product_id.toString(),
        sku: item.sku,
        name: item.name,
        price: item.price,
        quantity: item.qty,
        imageUrl: item.imgUrl,
        dataFields: {
          Product_Id: item.product_id,
          Product_Mode: item.vendor_of_shipping === "transfer" ? "Transfer" : "Buyout/Consignment",
          Stock: item.stock,
          Product_Name: item.name,
          Product_Image_Url: item.imgUrl,
          Product_Main_Category: cachedProduct.mainCategory || "優馬選品",
          Product_Sub_Category: cachedProduct.subCategory || "",
          Product_Url: "",
          Sku: item.sku,
          IsOnSale: cachedProduct.productIsOnSale,
          OnSale_Expiration_Time: cachedProduct.specialToDate,
          Currency: "TWD",
          Unit_Price: cachedProduct.unitPrice,
          Unit_Sale_Price: item.price,
          UCard_Price: Math.round(item.price * 0.83),
          Product_Wishlist_Count: cachedProduct.wishCount,
          Product_Review_Count: cachedProduct.reviewCount,
          Product_Review_Score: cachedProduct.score,
          Product_Reviews: cachedProduct.reviewList,
          subtotal,
        },
      };
    });

    window._iaq.push(["updateCart", itemArray]);
  },
  viewCart: (items, subtotal) => {
    const itemArray = items.map((item) => {
      const cachedProduct = getCachedProductFields(item.product_id);
      return {
        id: item.product_id.toString(),
        sku: item.sku,
        name: item.name,
        price: item.price,
        quantity: item.qty,
        imageUrl: item.imgUrl,
        dataFields: {
          Product_Id: item.product_id,
          Product_Mode: item.vendor_of_shipping === "transfer" ? "Transfer" : "Buyout/Consignment",
          Stock: item.stock,
          Product_Name: item.name,
          Product_Image_Url: item.imgUrl,
          Product_Main_Category: cachedProduct.mainCategory || "優馬選品",
          Product_Sub_Category: cachedProduct.subCategory || "",
          Product_Url: "",
          Sku: item.sku,
          IsOnSale: cachedProduct.productIsOnSale,
          OnSale_Expiration_Time: cachedProduct.specialToDate,
          Currency: "TWD",
          Unit_Price: cachedProduct.unitPrice,
          Unit_Sale_Price: item.price,
          UCard_Price: item.price * 0.83,
          Product_Wishlist_Count: cachedProduct.wishCount,
          Product_Review_Count: cachedProduct.reviewCount,
          Product_Review_Score: cachedProduct.score,
          Product_Reviews: cachedProduct.reviewList,
          subtotal,
        },
      };
    });
    window._iaq.push(["updateCart", itemArray]);
  },
  productReview: (productInfo) => {
    const uCardPrice = productInfo.sku === "URMARTVIP-BESTPRICE" ? 1500 : Math.round(productInfo.specialPrice * 0.83);
    const dataFields = {
      Product_Id: productInfo.id,
      Sku: productInfo.sku,
      Product_Name: productInfo.name,
      Product_Image_Url: productInfo.imgUrl,
      Product_Mode: productInfo.vendorofshipping === "47" ? "Transfer" : "Buyout/Consignment",
      Product_Main_Category: productInfo.mainCategory || "優馬選品",
      Product_Sub_Category: productInfo.subCategory || "",
      Product_Url: document.location.href,
      Stock: productInfo.stock,
      IsOnSale: productInfo.discount > 0,
      OnSale_Expiration_Time: productInfo.discount > 0 ? toISODate(productInfo.special_to_date) : undefined,
      Currency: "TWD",
      Unit_Price: productInfo.originalPrice,
      Unit_Sale_Price: productInfo.specialPrice,
      UCard_Price: uCardPrice,
      Product_Wishlist_count: productInfo.wishCount,
      Product_Review_Score: productInfo.ratingScore,
      Product_Review_Count: productInfo.review && productInfo.review.reviewsLength,
      Product_Reviews:
        productInfo.review &&
        productInfo.review.reviews.map((rv) => ({
          Review_Id: rv.review_id,
          Review_Create_Time: rv.created_at,
          Review_Score: parseInt(rv.rating_votes[0].percent, 10) / 20,
          Review_Detail: rv.detail,
        })),
    };
    window._iaq.push(["track", "ProductReview", dataFields]);
  },
  initialCheckout: () => {
    window._iaq.push(["track", "InitialCheckout"]);
  },
  purchase: (newTotals, items, orderId, couponCode) => {
    try {
      const customerData = storage.getCustomerData();
      const itemArray = Object.keys(items).map((itemId) => {
        const item = items[itemId];

        const cachedProduct = getCachedProductFields(item.product_id);
        return {
          id: item.product_id.toString(),
          sku: item.sku,
          name: item.name,
          price: item.price,
          quantity: item.qty,
          imageUrl: item.imgUrl,
          dataFields: {
            Product_Id: item.product_id,
            Sku: item.sku,
            Product_Name: item.name,
            Product_Main_Category: cachedProduct.mainCategory || "優馬選品",
            Product_Sub_Category: cachedProduct.subCategory || "",
            Product_Image_Url: "",
            Product_Mode: item.vendorOfShipping === "lms" ? "Buyout/Consignment" : "Transfer",
            Product_Url: "",
            Currency: "TWD",
            Unit_Sale_Price: item.price,
            Unit_Price: cachedProduct.unitPrice,
            UCard_Price: item.sku === "" ? 1500 : Math.round(item.price * 0.83),
            Coupon_Code: couponCode,
            Total_Discount_Amount: newTotals.discount,
            Customer_Group: customerData.groupId === 4 ? "Vip" : "Normal",
            Customer_Id: customerData.id,
            Customer_First_Name: customerData.firstname,
            Customer_Last_Name: customerData.lastname,
            Order_Id: orderId,
            Order_Create_Time: new Date(),
            Product_Wishlist_Count: cachedProduct.wishCount,
            Product_Review_Count: cachedProduct.reviewCount,
            Product_Review_Score: cachedProduct.score,
            Product_Reviews: cachedProduct.reviewList,
            Stock: item.maxQty,
            Grand_Total: newTotals.grandTotal,
            Shipping_Fee: newTotals.shippingAmount,
          },
        };
      });
      window._iaq.push(["trackPurchase", newTotals.grandTotal, itemArray]);
    } catch (error) {
      // errro
    }
  },
  completeLogin: (customer) => {
    const groupId = customer.groupId || customer.group_id;

    const dataField = {
      Customer_Id: customer.id,
      Customer_Last_Name: customer.lastname,
      Customer_First_Name: customer.firstname,
      Customer_Group: groupId === 4 ? "Vip" : "Normal",
      Email: customer.email,
      Phone: customer.custom_attributes[0].value,
    };

    window._iaq.push(["track", "CompleteLogin", dataField]);
  },
  /**
   *  customer.email,
      customer.id,
      customer.custom_attributes[0].value,
      customer.lastname,
      customer.firstname,
      socialId
   */
  completeRegistration: (customer, socialId) => {
    const {utmSource, utmMedium, utmContent, utmCampaign} = getUTMParams();
    const groupId = customer.groupId || customer.group_id;

    const dataField = {
      Customer_Id: customer.id,
      Customer_Last_Name: customer.lastname,
      Customer_First_Name: customer.firstname,
      Customer_Group: groupId === 4 ? "Vip" : "Normal",
      Device: "web",
      Register_Time: new Date(),
      Email: customer.email,
      Phone: customer.custom_attributes[0].value,
      Facebook_Id: socialId || "",
      Registration_Time: new Date(),
      Registration_Source: {
        UtmCampaign: utmCampaign,
        UtmContent: utmContent,
        UtmMedium: utmMedium,
        UtmSource: utmSource,
      },
    };
    window._iaq.push(["identify", customer.email, dataField]);
    window._iaq.push(["track", "CompleteRegistration", dataField]);
  },
  UCardPriceButton: (normalizedProduct) => {
    const {
      name,
      id,
      discount,
      sku,
      imgUrl,
      originalPrice,
      stock,
      special_to_date,
      specialPrice,
      vendorOfShippingLabel,
      mainCategory,
      subCategory,
    } = normalizedProduct;

    const dataFields = {
      Product_Id: `${id}`,
      Sku: sku,
      Product_Name: name,
      Product_Image_Url: imgUrl,
      Product_Mode: vendorOfShippingLabel === "transfer" ? "Transfer" : "Buyout/Consignment",
      Product_Main_Category: mainCategory || "優馬選品",
      Product_Sub_Category: subCategory || "",
      Product_Url: window.location.href,
      Stock: stock,
      IsOnSale: discount > 0,
      OnSale_Expiration_Time: discount > 0 ? special_to_date : "",
      Currency: "TWD",
      Unit_Price: originalPrice,
      Unit_Sale_Price: specialPrice,
      UCard_Price: sku !== VIPCardSku ? Math.round(specialPrice * 0.83) : 1500,
    };
    window._iaq.push(["track", "UCardPriceButton", dataFields]);
  },
  UCardPromoteBanner: () => {
    window._iaq.push(["track", "UCardPromoteBanner"]);
  },
  viewOrderStatus: (orderDetails) => {
    const customerData = storage.getCustomerData();
    const dataField = {
      Order_Id: orderDetails.entity_id,
      Order_Create_Time: orderDetails.created_at,
      Device: "Web",
      Customer_Id: customerData.id,
      Customer_Last_Name: customerData.firstname,
      Customer_First_Name: customerData.lastname,
      Customer_Group: customerData.groupId === 4 ? "Vip" : "Normal",
      Coupon_Code: orderDetails.coupon_code,
      Total_Discount_Amount: orderDetails.discount_amount,
      Shipping_Fee: orderDetails.shipping_amount,
      Grand_Total: orderDetails.grand_total,
    };
    window._iaq.push(["track", "ViewOrderStatus", dataField]);
  },
  additionalViewProduct: (normalizedProduct) => {
    const {name, id, discount, sku, imgUrl, originalPrice, stock, special_to_date, specialPrice, vendorOfShippingLabel} = normalizedProduct;
    const dataFields = {
      Product_Id: `${id}`,
      Sku: sku,
      Product_Name: name,
      Product_Image_Url: imgUrl,
      Product_Mode: vendorOfShippingLabel === "transfer" ? "Transfer" : "Buyout/Consignment",
      Product_Main_Category: "UrMart 優馬選品",
      Product_Sub_Category: "加價購",
      Product_Url: `https://urmart.com/default/product/${id}`,
      Stock: stock,
      IsOnSale: discount > 0,
      OnSale_Expiration_Time: discount > 0 ? special_to_date : "",
      Currency: "TWD",
      Unit_Price: originalPrice,
      Unit_Sale_Price: specialPrice,
      UCard_Price: sku !== VIPCardSku ? Math.round(specialPrice * 0.83) : 1500,
    };
    window._iaq.push(["track", "AdditionalViewProduct", dataFields]);
  },
  additionalAddToCart: (normalizedProduct) => {
    const {name, id, discount, sku, imgUrl, originalPrice, stock, special_to_date, specialPrice, vendorOfShippingLabel} = normalizedProduct;

    const dataFields = {
      Product_Id: `${id}`,
      Sku: sku,
      Product_Name: name,
      Product_Image_Url: imgUrl,
      Product_Mode: vendorOfShippingLabel === "transfer" ? "Transfer" : "Buyout/Consignment",
      Product_Main_Category: "UrMart 優馬選品",
      Product_Sub_Category: "加價購",
      Product_Url: `https://urmart.com/default/product/${id}`,
      Stock: stock,
      IsOnSale: discount > 0,
      OnSale_Expiration_Time: discount > 0 ? special_to_date : "",
      Currency: "TWD",
      Unit_Price: originalPrice,
      Unit_Sale_Price: specialPrice,
      UCard_Price: sku !== VIPCardSku ? Math.round(specialPrice * 0.83) : 1500,
    };

    window._iaq.push(["track", "AdditionalAddToCart", dataFields]);
  },
  setCustomerGroup: (email, customerGroupId) => {
    const dataField = {
      Customer_Group: customerGroupId === 4 ? "Vip" : "Normal",
    };
    window._iaq.push(["identify", email, dataField]);
  },
  couponUsed: (couponCode) => {
    const dataFields = {
      CouponCode: couponCode,
    };
    window._iaq.push(["track", "CouponUsed", dataFields]);
  },
  login: () => {
    window._iaq.push(["track", "Login"]);
  },
};

export default iterableAnalytics;

function toISODate(date) {
  if (date) {
    const dateString = date.replace(" ", "T");
    return new Date(dateString).toISOString();
  }

  return undefined;
}
