import Cookies from "js-cookie";
import moment from "moment";
import checkAppConfigSchema from "./checkAppConfigSchema";
import checkCustomerDataSchema from "./checkCustomerDataSchema";
import {LocalStorage} from "./LocalStorage";
import {SessionStorage} from "./SessionStorage";
import {
  ADULT_CONTENT,
  APP_CONFIG,
  CUSTOMER_DATA,
  FINAL_STORE,
  IS_LOGON,
  LINE_BINDING_STATE,
  PROMOTION_CLOSE,
  PROMOTION_VERSION,
  SEARCH_HISTORY,
  TOKEN_KEY,
  WIHSLIST_PRODUCTS,
} from "./storageKeyName";

function isCustomerExpired(lastUpdatedMilleSeconds) {
  if (!lastUpdatedMilleSeconds) {
    return true;
  }
  const now = new Date().getTime();

  if (now - lastUpdatedMilleSeconds >= 2 * 24 * 60 * 60 * 1000) {
    return true;
  }

  return false;
}

/**
 * Make local customer data expired after 15 minutes from the current time
 */
export function expireCustomerData() {
  try {
    const customerData = JSON.parse(LocalStorage.getItem(CUSTOMER_DATA));
    const {lastUpdatedTime} = customerData;

    const currentDate = moment();

    const exptedExpireTime = moment(lastUpdatedTime).add(2, "days");

    // 計算預計的 expire time, 並減 15 minutes
    const expireDuration = exptedExpireTime.diff(currentDate, "minutes") - 15;

    // 將 lastupate 往前推移預計 expire 的時間, 這樣到期日就會變成現在時間 + 15 分
    // foramt('x') => 轉成 milliseconds, e.g "158877734"
    let newExpireTime = moment(lastUpdatedTime).subtract(expireDuration, "minutes");
    newExpireTime = moment(newExpireTime).format("x");

    const newCustomerData = {
      ...customerData,
      lastUpdatedTime: parseInt(newExpireTime, 10),
    };
    LocalStorage.setItem(CUSTOMER_DATA, JSON.stringify(newCustomerData));
  } catch (error) {
    // Sentry auto capture error
  }
}

export function BrowserStorage() {
  const tokenKey = TOKEN_KEY;

  return {
    getToken: () => {
      return Cookies.get(tokenKey);
    },
    saveToken: (value) => {
      Cookies.set(tokenKey, value, {expires: 5, path: "/", secure: false});
    },
    clearToken: () => {
      Cookies.remove(tokenKey, {
        path: "/",
      });
    },
    setCustomerData: (data) => {
      try {
        const temp = {
          id: data.id,
          addresses: data.addresses,
          custom_attributes: data.custom_attributes,
          email: data.email,
          firstname: data.firstname,
          lastname: data.lastname,
          lastUpdatedTime: new Date().getTime(),
          groupId: data.group_id,
          invalidate: data.invalidate,
        };
        LocalStorage.setItem(CUSTOMER_DATA, JSON.stringify(temp));
        return true;
      } catch (error) {
        return false;
      }
    },
    getCustomerData: () => {
      try {
        const customerData = JSON.parse(LocalStorage.getItem(CUSTOMER_DATA));

        if (checkCustomerDataSchema(customerData) && !isCustomerExpired(customerData.lastUpdatedTime) && !customerData.invalidate) {
          return customerData;
        }
      } catch (error) {
        console.error(error);
      }
      return undefined;
    },
    resetGroupId: (id) => {
      try {
        const customerData = JSON.parse(LocalStorage.getItem(CUSTOMER_DATA));
        const newCustomerData = {
          ...customerData,
          groupId: id,
        };
        LocalStorage.setItem(CUSTOMER_DATA, JSON.stringify(newCustomerData));
      } catch (error) {
        console.error(error);
      }
    },
    setIsLogin: () => {
      // 給一個很長的到期日, 不然預設會是 Session Cookie
      Cookies.set(IS_LOGON, "true", {expires: 1825});
    },
  };
}

export function getPromotionVersion() {
  return Cookies.get(PROMOTION_VERSION);
}

export function isPromotionClosed() {
  return Cookies.get(PROMOTION_CLOSE);
}

export function updatePromotionVersion(value) {
  Cookies.set(PROMOTION_VERSION, value, {
    expires: 3,
    path: "/",
  });
}

export function updatePromotionCloseState(state) {
  Cookies.set(PROMOTION_CLOSE, state, {
    expires: 3,
    path: "/",
  });
}

function isExpired(lastUpdatedMilleSeconds) {
  const lastUpdatedTime = moment(lastUpdatedMilleSeconds);
  const midnight = moment().set({hour: 0, minute: 0, second: 0, millisecond: 0});
  const eightAM = moment().set({hour: 8, minute: 0, second: 0, millisecond: 0});
  const fourPM = moment().set({hour: 16, minute: 0, second: 0, millisecond: 0});
  const now = moment();

  if (lastUpdatedTime.isSameOrBefore(midnight) && now.isSameOrAfter(midnight)) {
    return true;
  }
  if (lastUpdatedTime.isSameOrBefore(eightAM) && now.isSameOrAfter(eightAM)) {
    return true;
  }
  if (lastUpdatedTime.isSameOrBefore(fourPM) && now.isSameOrAfter(fourPM)) {
    return true;
  }

  return false;
}

export function getLocalAppConfig() {
  try {
    const config = JSON.parse(LocalStorage.getItem(APP_CONFIG));

    if (isExpired(config.lastUpdatedTime)) {
      return null;
    }
    // check the data schema
    if (!checkAppConfigSchema(config)) {
      return null;
    }
    return config;
  } catch (error) {
    return false;
  }
}

export function saveLocalAppConfig(appConfig) {
  try {
    // only save the following keys
    const config = {...appConfig};
    const temp = {
      add_price_buy: config.add_price_buy,
      brands: config.brands,
      cvs_constrain: config.cvs_constrain,
      hot_sale_id: config.hot_sale_id,
      promotion_banner: config.promotion_banner,
      search_recommend: config.search_recommend,
      shipping_threshold: config.shipping_threshold,
      vendor_of_shipping: config.vendor_of_shipping,
      lastUpdatedTime: new Date().getTime(),
    };

    LocalStorage.setItem(APP_CONFIG, JSON.stringify(temp));
    return true;
  } catch (error) {
    return false;
  }
}

export const checkAsked = () => {
  return Cookies.get(ADULT_CONTENT);
};

export const getAdultContentPermissionFromCookie = () => {
  return Cookies.get(ADULT_CONTENT) === "true" || false;
};

export const setAdultContentPermissionInCookie = (permission) => {
  Cookies.set(ADULT_CONTENT, permission, {
    path: "/",
    expires: 365,
  });
};

export const setLineBindingState = (value) => {
  Cookies.set(LINE_BINDING_STATE, value);
};

export const getLineBindingState = () => {
  return Cookies.get(LINE_BINDING_STATE);
};

export const getSearchHistory = () => {
  try {
    const searchTexts = JSON.parse(LocalStorage.getItem(SEARCH_HISTORY)); // null or "['aaa', 'nnn']"
    return searchTexts || [];
  } catch (error) {
    return [];
  }
};

export const saveSearchHistory = (searchString) => {
  try {
    const searchTexts = JSON.parse(LocalStorage.getItem(SEARCH_HISTORY)); // null or "['aaa', 'nnn']"

    // 檢查是否有已經有搜尋值的紀錄，有的話不做任何事情
    if (searchTexts.includes(searchString)) {
      return;
    }

    // 如果沒有該搜尋值，將值存入第一個
    searchTexts.unshift(searchString);

    // 取前 10 個
    const only10searchTexts = searchTexts.slice(0, 9);

    LocalStorage.setItem(SEARCH_HISTORY, JSON.stringify(only10searchTexts));
  } catch (error) {
    // if any parsing error, reset local storage to array format
    if (searchString) {
      LocalStorage.setItem(SEARCH_HISTORY, JSON.stringify([searchString]));
    }
    console.error(error);
  }
};

export const clearSearchHistory = () => {
  try {
    LocalStorage.removeItem(SEARCH_HISTORY);
  } catch (error) {
    console.error(error);
  }
};

export const saveFinalStore = (finalStore) => {
  try {
    SessionStorage.setItem(FINAL_STORE, finalStore);
  } catch (error) {
    console.error(error);
  }
};

export const getFinalStore = () => {
  try {
    return SessionStorage.getItem(FINAL_STORE);
  } catch (error) {
    console.error(error);
    return "default";
  }
};

export const getLocalWishlistProducts = () => {
  try {
    return JSON.parse(LocalStorage.getItem(WIHSLIST_PRODUCTS));
  } catch (error) {
    return null;
  }
};

export const saveWishlistProductsToLocal = (wishlistProducts) => {
  try {
    const previousWishlistProducts = getLocalWishlistProducts();

    if (previousWishlistProducts) {
      LocalStorage.setItem(WIHSLIST_PRODUCTS, JSON.stringify({...previousWishlistProducts, ...wishlistProducts}));
    } else {
      LocalStorage.setItem(WIHSLIST_PRODUCTS, JSON.stringify(wishlistProducts));
    }
  } catch (error) {
    console.error(error);
  }
};

export const removeWishlistProductsFromLocal = (removedWishlistProductId) => {
  try {
    // try get local storage data
    const previousWishProducts = getLocalWishlistProducts();

    if (previousWishProducts) {
      delete previousWishProducts[removedWishlistProductId];
      LocalStorage.setItem(WIHSLIST_PRODUCTS, JSON.stringify(previousWishProducts));
    }
  } catch (error) {
    console.error(error);
  }
};

export const clearWishlistProductsFromLocal = () => {
  try {
    LocalStorage.removeItem(WIHSLIST_PRODUCTS);
  } catch (error) {
    console.error(error);
  }
};

export function clearLocalCustomerInfo() {
  try {
    LocalStorage.removeItem(CUSTOMER_DATA);
  } catch (error) {
    console.error(error);
  }
}
