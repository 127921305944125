/* eslint-disable camelcase */
import {LocalStorage} from "@storage/LocalStorage";
import {UTM_PARAMS} from "@storage/storageKeyName";
import queryString from "query-string";
import {captureSentryException} from "./setupSentry";

export default function parseAndSaveUtmParams() {
  try {
    const searchParameters = queryString.parse(window.location.search);
    const {utm_source, utm_medium, utm_campaign, utm_term, utm_content} = searchParameters;
    /**
     * 有解析到 utm 參數才更新 localstorage 的資料, 並限制最大字數為 50
     * !! Attention duplicate key
     * queryString.parse('foo=1&foo=2&foo=3');
     * // {foo: ['1', '2', '3']}
     * @see https://www.npmjs.com/package/query-string
     */
    if (utm_source || utm_medium || utm_campaign || utm_term || utm_content) {
      const utmSourceString = Array.isArray(utm_source) ? utm_source[0] : (utm_source && utm_source.slice(0, 50)) || "";
      const isFromFacebook = /facebook/i.test(utmSourceString);
      const currentSource = getCurrentUtmSource();
      const isCurrentFacebook = /facebook/i.test(currentSource);

      if (isFromFacebook) saveUtmParams();
      else if (!isCurrentFacebook) saveUtmParams();
    }
  } catch (error) {
    captureSentryException(error);
  }
}

function saveUtmParams() {
  const searchParameters = queryString.parse(window.location.search);
  const {utm_source, utm_medium, utm_campaign, utm_term, utm_content} = searchParameters;

  const utmParams = {
    utmSource: Array.isArray(utm_source) ? utm_source[0] : (utm_source && utm_source.slice(0, 50)) || "",
    utmMedium: Array.isArray(utm_medium) ? utm_medium[0] : (utm_medium && utm_medium.slice(0, 50)) || "",
    utmCampaign: Array.isArray(utm_campaign) ? utm_campaign[0] : (utm_campaign && utm_campaign.slice(0, 50)) || "",
    utmTerm: Array.isArray(utm_term) ? utm_term[0] : (utm_term && utm_term.slice(0, 50)) || "",
    utmContent: Array.isArray(utm_content) ? utm_content[0] : (utm_content && utm_content.slice(0, 50)) || "",
    lastUpdatedTime: new Date().getTime(),
  };
  LocalStorage.setItem(UTM_PARAMS, JSON.stringify(utmParams));
}

function getCurrentUtmSource() {
  const currentUTMParamsString = LocalStorage.getItem(UTM_PARAMS) || null;
  const currentUTMParams = JSON.parse(currentUTMParamsString);

  return currentUTMParams && currentUTMParams.utmSource;
}
