const GA = {
  initializeGA: () => {
    window.ga("require", "ec");
    window.ga("require", "linker");
    window.ga("linker:autoLink", [
      "emap.presco.com.tw",
      "map.com.tw",
      "tappaysdk.com",
      "onlinepay.jkopay.com",
      "preview.page.link",
      "*.useinsider.com",
    ]);
  },
  trackPageView: (pagePath, pageTitle) => {
    // set tracker object first, and send the following pageview
    window.ga("set", "page", pagePath);
    window.ga("set", "title", pageTitle || "UrMart - 優馬選品");
    window.ga("send", "pageview");
  },
  sendEvent: (category, action, label, value = 0) => {
    window.ga("send", "event", category, action, label, value);
  },
  clickPromotion: (data) => {
    window.ga("ec:addPromo", {
      id: data.id,
      name: data.name,
      position: `banner_slot${data.position}`,
    });

    // Send the promo_click action with an event.
    window.ga("ec:setAction", "promo_click");
    window.ga("send", "event", "Enhanced-Ecommerce", "click", "promote banner");
  },
  addImpression: (data) => {
    window.ga("ec:addImpression", {
      id: data.sku,
      name: data.name,
      category: data.category || "",
      brand: data.brand,
      list: data.list, // e.g cateogory page
      position: data.position,
    });
  },
  clickProduct: (clickedProduct) => {
    window.ga("ec:addProduct", {
      // Provide product details in a productFieldObject.
      id: clickedProduct.sku,
      name: clickedProduct.name,
      category: clickedProduct.categoryName || "",
      brand: clickedProduct.brand,
      position: clickedProduct.position,
      price: `${clickedProduct.price}`,
    });

    window.ga("ec:setAction", "click", {
      list: clickedProduct.list, // e.g cateogory page
    });
    window.ga("send", "event", "Enhanced-Ecommerce", "click", "click product grid");
  },
  viewProductDetail: (productDetail) => {
    window.ga("ec:addProduct", {
      id: productDetail.sku,
      name: productDetail.name,
      category: productDetail.category || "",
      brand: productDetail.brand,
      position: productDetail.position || 0,
      price: `${productDetail.price}`,
    });

    window.ga("ec:setAction", "detail");
  },
  addToCart: (data, type) => {
    const purchaseItemFrom = localStorage.getItem("PurchaseItemFrom") ? JSON.parse(localStorage.getItem("PurchaseItemFrom")) : {};

    window.ga("ec:addProduct", {
      id: data.sku,
      name: data.name,
      category: data.category || "",
      brand: data.brand,
      price: data.price,
      quantity: data.qty,
    });

    window.ga("ec:setAction", type);
    window.ga("send", "event", "Enhanced-Ecommerce", "click", "add to cart");

    purchaseItemFrom[data.sku] = data.category;
    localStorage.setItem("PurchaseItemFrom", JSON.stringify(purchaseItemFrom));
  },
  trackCheckoutStep: (datas, step) => {
    if (Object.keys(datas).length > 0) {
      Object.keys(datas).forEach((key) => {
        const data = datas[key];
        window.ga("ec:addProduct", {
          id: data.sku,
          name: data.name,
          price: data.price,
          quantity: data.qty,
        });
      });

      window.ga("ec:setAction", "checkout", {step});
      window.ga("send", "event", "Enhanced-Ecommerce", "click", `enter step ${step}`);
    }
  },
  trackCheckoutOption: (data, step) => {
    window.ga("ec:setAction", "checkout_option", {
      step,
      option: data,
    });

    window.ga("send", "event", "Enhanced-Ecommerce", "click", `step ${step} option : ${data}`);
  },
  purchase: (order, cartItems) => {
    try {
      const purchaseItemFrom = window.localStorage.getItem("PurchaseItemFrom") ? JSON.parse(localStorage.getItem("PurchaseItemFrom")) : {};

      Object.keys(cartItems).forEach((key) => {
        const data = cartItems[key];
        const category = purchaseItemFrom[data.sku] ? purchaseItemFrom[data.sku] : null;
        window.ga("ec:addProduct", {
          id: data.sku,
          name: data.name,
          category: category || "",
          price: data.price,
          quantity: data.qty,
        });
      });

      // Transaction level information is provided via an actionFieldObject.
      window.ga("ec:setAction", "purchase", {
        id: `${order.id}`,
        revenue: `${order.revenue}`,
        affiliation: "UrMart - 優馬選品",
        shipping: `${order.shipping}`,
        tax: "0",
        coupon: "",
      });

      window.ga("send", "event", "Enhanced-Ecommerce", "click", "purchase");
      window.localStorage.removeItem("PurchaseItemFrom");
    } catch (error) {
      Object.keys(cartItems).forEach((key) => {
        const data = cartItems[key];
        window.ga("ec:addProduct", {
          id: data.sku,
          name: data.name,
          price: data.price,
          quantity: data.qty,
        });
      });

      // Transaction level information is provided via an actionFieldObject.
      window.ga("ec:setAction", "purchase", {
        id: `${order.id}`,
        revenue: `${order.revenue}`,
        affiliation: "UrMart - 優馬選品",
        shipping: `${order.shipping}`,
        tax: "0",
        coupon: "",
      });

      window.ga("send", "event", "Enhanced-Ecommerce", "click", "purchase");
    }
  },
};
export default GA;
