// @flow
import React from "react";
import Media from "react-media";
import { mediaQueryMdScreen } from "@shared/configs";

type Props = {
  search: () => void,
  searchString: string,
  setString: () => void,
  className: string,
  history: Object,
  storeName: string,
};

export default function Searchbar(props: Props) {
  const {search, searchString, setString, className, history, storeName, style} = props;

  function onClick(event) {
    event.preventDefault();

    if (window.location.pathname.indexOf("search") === -1) {
      if (storeName !== "proteinshop" && storeName !== "morningshop" && storeName !== "default") {
        history.push(`/default/search`);
      } else {
        history.push(`/${storeName}/search`);
      }
    }
  }

  return (
    <Media query={mediaQueryMdScreen}>
      {(isMatches) =>
        isMatches ? (
          <div className="col-xs-10">
            <form className={`${className} ur-search__bar__form row middle-xs`} onSubmit={search}>
              <label className="ur-search__bar__icon" htmlFor="searchQuery">
                <i className="material-icons col-xs-1 " role="button" tabIndex={0} style={{verticalAlign: "middle"}}>
                  search
                </i>
                <input
                  className="col-xs-11 ur-text-level-7"
                  name="searchQuery"
                  id="searchQuery"
                  value={searchString}
                  placeholder={searchString || "請輸入您想搜尋的商品"}
                  onChange={setString}
                  type="text"
                />
              </label>
            </form>
          </div>
        ) : (
          <div className="col-xs-10">
            <form className={`${className} ur-search__bar__form row middle-xs`} onSubmit={search}>
              <label className="ur-search__bar__icon" htmlFor="searchQuery" onClick={onClick}>
                <i className="material-icons col-xs-2" onClick={search} role="button" tabIndex={0} style={{verticalAlign: "middle"}}>
                  search
                </i>
                <input
                  className="col-xs-10 ur-text-level-7"
                  name="searchQuery"
                  id="searchQuery"
                  value={searchString}
                  placeholder={searchString || "請輸入您想搜尋的商品"}
                  onChange={setString}
                  type="text"
                />
              </label>
            </form>
          </div>
        )
      }
    </Media>
  );
}
