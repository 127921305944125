const CRMInsider = {
  initialize: () => {
    window.insider_object = window.insider_object || {};
  },
  setUserData: ({email, name, surname, group_id}) => {
    const insiderObj = window.insider_object || {};
    insiderObj.user = {
      email,
      gdpr_optin: true,
      email_optin: true,
      name,
      surname,
      group_id,
    };
  },
  setPage: (type) => {
    const insiderObj = window.insider_object || {};
    insiderObj.page = {
      type,
    };
  },
  setProduct: (product, breadcrumbs, productPageUrl, productImageUrl) => {
    const insiderObj = window.insider_object || {};
    insiderObj.product = {
      id: product.id,
      name: product.name,
      taxonomy: breadcrumbs,
      currency: "TWD",
      unit_price: product.originalPrice,
      unit_sale_price: product.specialPrice,
      url: productPageUrl,
      product_image_url: productImageUrl,
      stock: product.stock,
      is_on_sale: product.isOnSale,
      special_to_date: product.isOnSale ? product.specialToDate : "",
    };
  },
  setUserBasket: (cartItems, breadcrumb, cartTotal) => {
    const lineItems = Object.keys(cartItems).map((key) => {
      const item = cartItems[key];
      const productId = cartItems[key].product_id || cartItems[key].item_id;
      if (breadcrumb[productId]) {
        const product = breadcrumb[productId];
        return {
          id: product.id,
          name: product.name,
          taxonomy: product.taxonomy,
          currency: "TWD",
          unit_price: product.unit_price,
          unit_sale_price: product.unit_sale_price,
          url: product.url,
          product_image_url: product.imageUrl,
          quantity: item.qty,
          subtotal: item.row_total_with_discount,
        };
      }
      return {
        id: item.product_id || item.item_id,
        name: item.name,
        taxonomy: ["優馬選品", item.name],
        currency: "TWD",
        unit_price: item.price,
        unit_sale_price: item.price,
        url: window.location.href,
        product_image_url: item.imgUrl,
        quantity: item.qty,
        subtotal: item.row_total_with_discount,
      };
    });
    const insiderObj = window.insider_object || {};
    insiderObj.basket = {
      currency: "TWD",
      line_items: lineItems,
      total: cartTotal,
    };
  },
  trasction: (cartItems, orderId, orderTotal) => {
    let lineItems = [];
    if (window.insider_object && window.insider_object.basket) {
      // retrive cartItems
      lineItems = window.insider_object.basket.line_items;
      window.insider_object.transaction = {
        order_id: orderId,
        currency: "TWD",
        total: orderTotal,
        line_items: lineItems,
      };
    } else {
      const insiderObj = window.insider_object || {};
      lineItems = Object.keys(cartItems).map((key) => {
        const item = cartItems[key];
        return {
          id: item.product_id || item.item_id,
          name: item.name,
          taxonomy: ["優馬選品", item.name],
          currency: "TWD",
          unit_price: item.price,
          unit_sale_price: item.price,
          url: window.location.href,
          product_image_url: item.imgUrl,
          quantity: item.qty,
          subtotal: item.row_total_with_discount,
        };
      });
      insiderObj.transaction = {
        order_id: orderId,
        currency: "TWD",
        total: orderTotal,
        line_items: lineItems,
      };
    }
  },
};

export default CRMInsider;
