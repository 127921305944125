import React from 'react';

function CartAdd ({itemName}) {
    return (
        <div className='ur-message-popup__content'>

            <i className="material-icons ur-message-popup__content__icon__top" style={{display: 'block', color: 'green'}}>
                arrow_downward
            </i>
            <i className="material-icons ur-message-popup__content__icon__bottom" style={{display: 'block'}}>
                shopping_basket
            </i>
            <p className='ur-message-popup__content__message'>{'商品已加入購物車'}</p>
        </div>
    );
}

export default CartAdd;