import {getDefaultStoreName, getStoreTitle} from "@shared/appHelpers";
import {AppRouter} from "@shared/AppRouter";
import React from "react";
import {Link} from "react-router-dom";
import MessagerIcon from "./MessagerIcon";
import StoreLogo from "./StoreLogo";

export default function ProductHeader(props) {
  const {match} = props;
  const storeName = getDefaultStoreName(match.params.storeName);
  return (
    <>
      <div className="ur-page-header__icon left" onClick={() => AppRouter.getInstance().goBack()} role="button" tabIndex={0}>
        <i className="material-icons">navigate_before</i>
      </div>
      <h1 className="ur-page-header__title col-xs-12">
        <Link to={`/${storeName || "default"}`} className="color-white">
          <StoreLogo storeName={storeName} />
          {getStoreTitle(storeName)}
        </Link>
      </h1>
      <MessagerIcon className="ur-page-header__icon right" color="white" />
    </>
  );
}
