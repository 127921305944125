import {applyMiddleware, combineReducers, createStore} from "redux";
import {composeWithDevTools} from "redux-devtools-extension";
import thunk from "redux-thunk";
import adultContent from "./reducers/adultContent";
import appConfig from "./reducers/appConfig";
import auth from "./reducers/auth";
import breadcrumb from "./reducers/breadcrumb";
import cart from "./reducers/cart";
import categories from "./reducers/categories";
import checkout from "./reducers/checkout";
import global from "./reducers/global";
import homepage from "./reducers/homepage";
import list from "./reducers/list";
import orders from "./reducers/orders";
import products from "./reducers/products";
import register from "./reducers/register";
import review from "./reducers/review";
import vip from "./reducers/vip";
import wishlist from "./reducers/wishlist";

const rootReducer = combineReducers({
  appConfig,
  categories,
  products,
  cart,
  homepage,
  auth,
  checkout,
  global,
  orders,
  wishlist,
  register,
  list,
  breadcrumb,
  adultContent,
  vip,
  review,
});

// Note: this API requires redux@>=3.1.0
const configureStore = () => {
  if (process.env.NODE_ENV === "development") {
    return createStore(rootReducer, composeWithDevTools(applyMiddleware(thunk)));
  }
  return createStore(rootReducer, applyMiddleware(thunk));
};

export default configureStore;
