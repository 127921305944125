import gtagTrack from "@shared/gtagTrack";
import {getGroupProdcutListPrice} from "@shared/productUtils";
import React from "react";
import FBPixel from "../FBPixel/index";
import GA from "./GA";

export default class Tracking extends React.Component {
  componentDidMount() {
    const {title, productData, productList, doNotSendPageview, list} = this.props;

    if (!doNotSendPageview) {
      GA.trackPageView(window.location.pathname, title);
      FBPixel.pageview();
    }

    if (productData) {
      GA.viewProductDetail(productData);
      gtagTrack.viewItem(productData);
      FBPixel.viewContent({
        name: productData.name,
        specialPrice: productData.specialPrice,
        sku: productData.sku,
      });
    }

    if (productList && productList.length > 0) {
      gtagTrack.viewItemList({
        pageName: title,
        items: productList,
      });
      productList.forEach((product, index) => {
        if (product.type_id === "grouped") {
          // grouped product
          const item = product.product_links[0];
          if (item) {
            const {specialPrice} = getGroupProdcutListPrice(item);
            GA.clickProduct({
              sku: item.linked_product_sku,
              name: item.extension_attributes.name,
              price: `${specialPrice}`,
              category: title,
              brand: title,
              list,
              position: index + 1,
            });
          }
        } else {
          GA.addImpression({
            sku: product.sku,
            name: product.name,
            price: `${product.specialPrice}`,
            category: title,
            brand: title,
            list,
            position: index + 1,
          });
        }
      });

      // Ref: https://stackoverflow.com/questions/25140579/tracking-catalog-product-impressions-enhanced-ecommerce-google-analytics
      window.ga("send", "event", "Enhanced-Ecommerce", "impression", "view product list");
    }
  }

  componentDidUpdate(prevProps) {
    const {title, productList, list} = this.props;
    if (productList && prevProps.productList.length < productList.length) {
      productList.forEach((product, index) => {
        GA.addImpression({
          ...product,
          category: title,
          brand: title,
          list,
          position: index + 1,
        });
      });
      window.ga("send", "event", "Enhanced-Ecommerce", "impression", "view product list");
    }
  }

  render() {
    return null;
  }
}
