import {s3HostWithCache} from "./configs";
import {
  getGroupProductPrice,
  getHideVIPAttributes,
  getIsVIPLimitedAttributes,
  getSimpleProductPrice,
  retriveProductCustomAttributes,
} from "./productUtils";

/**
 * Retrive product info
 * @param {Object} product - product infomation
 */
export function formatProduct(product) {
  let formattedProduct = {};

  try {
    // retrive necessary cusom_attributes value
    const productCustomAttributes = retriveProductCustomAttributes(product, [
      "image",
      "effective_date",
      "preorder_shipping_date",
      "description",
      "short_description",
      "brand",
      "is_adult",
      "vendorofshipping",
      "meta_description",
      "meta_title",
    ]);
    const {originalPrice, specialPrice, discount, specialFromDate, specialToDate} =
      product.type_id === "grouped" ? getGroupProductPrice(product.product_links) : getSimpleProductPrice(product);

    formattedProduct = {
      id: product.id,
      sku: product.sku,
      name: product.name,
      type_id: product.type_id,
      price: product.price || 0, // grouped product 沒有價格 key
      is_in_stock: product.extension_attributes.stock_item.is_in_stock,
      originalPrice,
      specialPrice,
      discount,
      media_gallery_entries: product.media_gallery_entries,
      product_links: product.product_links || [],
      stock: computeMaxStock(product),
      max_sale_qty: product.extension_attributes.stock_item.max_sale_qty,
      recommand_categoryIds: product.extension_attributes.category_ids,
      hotsaleId: product.extension_attributes.hotsale_id,
      effective_date: productCustomAttributes.effective_date,
      pre_order_shipping_date: productCustomAttributes.preorder_shipping_date || "",
      description: productCustomAttributes.description,
      short_description: productCustomAttributes.short_description,
      imgUrl: `${s3HostWithCache}${productCustomAttributes.image}`,
      image: productCustomAttributes.image,
      brand: productCustomAttributes.brand,
      special_from_date: specialFromDate || "",
      special_to_date: specialToDate || "",
      is_adult: productCustomAttributes.is_adult === "1",
      vendorofshipping: productCustomAttributes.vendorofshipping,
      isVIPLimited: getIsVIPLimitedAttributes(product),
      hideVIPPrice: getHideVIPAttributes(product),
      meta_description: productCustomAttributes.meta_description,
      meta_title: productCustomAttributes.meta_title,
    };
    return formattedProduct;
  } catch (error) {
    return {id: "error"};
  }
}

export function formatProductList(prodctList) {
  const formattedList = [];
  Object.keys(prodctList).forEach((key) => {
    // status === 2 => disabled product
    // visibility == 1 => only visible individually,
    const formatedProduct = formatProduct(prodctList[key]);
    if (prodctList[key].status !== 2 || (prodctList[key].visibility !== 1 && formatedProduct)) {
      formattedList.push(formatedProduct);
    }
  });

  return formattedList;
}
// eslint-disable-next-line camelcase
function computeMaxStock({product_links, extension_attributes}) {
  // eslint-disable-next-line camelcase
  if (product_links && product_links.length > 0) {
    const stocks = product_links.map((product) => product.extension_attributes.stock_qty);
    return Math.max(...stocks);
  }
  return extension_attributes.stock_item.qty;
}
