import React from "react";
import {Link} from "react-router-dom";
import {liveHelpLink} from "../../shared/configs";

export default function MobileFooter({background}) {
  return (
    <footer className={`ur-footer ${background}`}>
      <section className="row top-xs ur-footer__content">
        <div className="col-xs-2-4 text-center" style={{margin: "1rem 0"}}>
          <Link to="/default/about" className="ur-footer__link">
            <i className="material-icons">store</i>
          </Link>
          <p>商店資訊</p>
        </div>
        <div className="col-xs-2-4 text-center" style={{margin: "1rem 0"}}>
          <Link to="/default/privacy-policy" className="ur-footer__link">
            <i className="material-icons">visibility_off</i>
          </Link>
          <p>隱私權</p>
        </div>

        <div className="col-xs-2-4 text-center" style={{margin: "1rem 0"}}>
          <Link to="/default/member-rights" className="ur-footer__link">
            <i className="material-icons">account_box</i>
          </Link>
          <p>服務條款</p>
        </div>
        <div className="col-xs-2-4 text-center" style={{margin: "1rem 0"}}>
          <Link to="/default/customer-service" className="ur-footer__link">
            <i className="material-icons">info</i>
          </Link>
          <p>客服資訊</p>
        </div>
        <div className="col-xs-2-4 text-center" style={{margin: "1rem 0"}}>
          <a href={liveHelpLink} target="_blank" rel="noopener noreferrer" className="ur-footer__link">
            <i className="material-icons">live_help</i>
          </a>
          <p>常見問題</p>
        </div>
      </section>
    </footer>
  );
}
