import {MORNING_SHOP, PROTEIN_SHOP} from "../types/typeConstants";

export function getDefaultStoreName(storeName) {
  if (storeName === "morningshop" || storeName === "proteinshop" || storeName === "default") {
    return storeName;
  }

  return "default";
}

export function getStoreTitle(storeName) {
  switch (storeName) {
    case PROTEIN_SHOP:
      return "運動吃蛋白";
    case MORNING_SHOP:
      return "早餐吃麥片";
    default:
      return "UrMart";
  }
}

export function getBackgroundColor(storeName) {
  switch (storeName) {
    case PROTEIN_SHOP:
      return "#2080F8";
    case MORNING_SHOP:
      return "#EC6922";
    default:
      return "#5C6AC4";
  }
}
