import {LocalStorage} from "./LocalStorage";
import {PRODUCTS_CACHE} from "./storageKeyName";

const key = PRODUCTS_CACHE;

export default function cacheProdcutFields(productId, data) {
  let previousData = "{'preservedOrder': '[]'}";
  try {
    previousData = LocalStorage.getItem(key);

    const parsedData = previousData ? JSON.parse(previousData) : {preservedOrder: []};

    // Only save 50 product's info in cache + 1 (preservedOrder)
    const keys = Object.keys(parsedData);
    if (keys.length >= 51 && parsedData.preservedOrder.length > 0) {
      const deletedKey = parsedData.preservedOrder[0];
      parsedData.preservedOrder.shift();
      delete parsedData[deletedKey];
    }

    // prevent duplicate key
    const preservedOrder =
      parsedData.preservedOrder.indexOf(productId) !== -1 ? parsedData.preservedOrder : [...parsedData.preservedOrder, productId];

    const cachedData = JSON.stringify({
      ...parsedData,
      [productId]: {
        ...data,
      },
      preservedOrder,
    });
    LocalStorage.setItem(key, cachedData);
  } catch (error) {
    // Error saving data
    console.error("error", error);
  }
}

export function getCachedProductFields(productId) {
  try {
    const cachedData = LocalStorage.getItem(key);
    const parsedData = JSON.parse(cachedData);
    const product = parsedData[productId];
    return product || {};
  } catch (error) {
    return {};
  }
}
