import * as Sentry from "@sentry/browser";
import {customerEndPoints} from "./APIEndPoints";
import {appName, appVersion} from "./configs";
import {getErrorMessageTitle, getRequestPayload, retriveUserEmailOrPhoneFromPaylod} from "./getErrorMessgaeTitle";

const TAGS = {
  apiTag: "api",
  componentTag: "component",
};

export function initializeSentry() {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_CONFIG,
    environment: process.env.REACT_APP_SENTRY_ENV,
    sampleRate: 0.8, // random sampling error
    release: `${appName}@${appVersion}`,
    ignoreErrors: [/UnhandledRejection/, /PromiseRejectionEvent/, /Anonymous function(gtm)/, /trackFbEvent(pixel)/, /innerHTML/],
    beforeSend(event, eventHint) {
      // @see https://docs.sentry.io/platforms/node/guides/express/configuration/filtering/
      if (
        (eventHint.originalException && eventHint.originalException === "API Error") ||
        (eventHint.originalException && eventHint.originalException === "Render Error")
      ) {
        return event;
      }
      return null;
    },
  });
}

export function setSentryUserEmail(email, id) {
  Sentry.configureScope((scope) => {
    scope.setUser({email, id});
  });
}

export function clearSentryUserEmail() {
  Sentry.configureScope((scope) => {
    scope.setUser({});
  });
}

function reportSentryError(e, {tag, requestPayload, requestAPI, responseHeaders, responseMessage, errorMessageTitle, useremail}) {
  Sentry.withScope((scope) => {
    scope.setLevel("error");
    scope.setTag(tag, requestAPI);
    scope.setTag("auth.email", useremail);
    scope.setExtra("responseMessage", responseMessage);
    scope.setExtra("requestPayload", requestPayload);
    scope.setExtra("responseHeaders", responseHeaders);
    Sentry.captureMessage(errorMessageTitle);
  });
}

export function sendAPIerrorReport(axiosError, currentApiEndPoint) {
  let responseMessage = "";
  let responseHeaders = "{}";

  if (axiosError.response) {
    responseMessage = JSON.stringify(axiosError.response.data);
    responseHeaders = JSON.stringify(axiosError.response.headers);
  } else if (axiosError.request) {
    // The request was made but no response was received
    responseMessage = "no server response";
  } else {
    // Something happened in setting up the request that triggered an Error
    responseMessage = "Setting request error";
  }

  reportSentryError(axiosError, {
    tag: TAGS.apiTag,
    requestAPI: axiosError.config.url,
    requestPayload: getRequestPayload(axiosError.config.data),
    responseHeaders,
    responseMessage,
    errorMessageTitle: getErrorMessageTitle(currentApiEndPoint),
    useremail: customerEndPoints.includes(currentApiEndPoint) ? retriveUserEmailOrPhoneFromPaylod(axiosError.config.data) : undefined,
  });
}

export function sendComponentError(componentError, componentErrorStack) {
  reportSentryError(componentError, {
    tag: TAGS.componentTag,
    requestPayload: "",
    requestAPI: "",
    responseHeaders: "",
    useremail: "",
    responseMessage: JSON.stringify(componentErrorStack),
    errorMessageTitle: "Render Error",
  });
}

export function captureSentryException(error) {
  Sentry.captureException(error);
}
