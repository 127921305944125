import throttle from "lodash/throttle";

function smallCategoryListScrollListener() {
  const target = document.getElementById("ur-category-top");
  const smallCate = document.getElementById("small-desktop");
  if (target && smallCate) {
    const rect = target.getBoundingClientRect();

    if (-rect.y >= rect.height) {
      smallCate.style.display = "block";
    } else {
      smallCate.style.display = "none";
    }
  }
}

function stickMobileIndexHeader() {
  const header = document.getElementById("ur-fixed-header");
  if (!header) {
    return;
  }
  if (header.offsetTop < window.scrollY) {
    header.style.position = "fixed";
    header.style.top = "0px";
    header.style.width = "100%";
  } else {
    header.style.position = "initial";
    header.style.top = "initial";
  }
}

function stickMobileStoreTab() {
  const target = document.getElementById("ur-category");
  const tab = document.getElementById("ur-store-tab");

  if (!tab || !target) {
    return;
  }

  if (tab.offsetTop < window.scrollY) {
    tab.style.position = "fixed";
    tab.style.top = "0";
    tab.style.width = "100%";
    target.style.marginTop = `${tab.clientHeight}px`;
  } else {
    tab.style.position = "initial";
    tab.style.top = "initial";
    target.style.marginTop = "initial";
  }
}

function stickMobileFilterBar() {
  const target = document.getElementById("ur-filter-bar");
  const {scrollY} = window;
  if (!target || !target) {
    return;
  }
  if (target.offsetTop < scrollY) {
    target.style.position = "fixed";
    target.style.top = "0px";
    target.style.zIndex = "2000";
    target.style.width = "100%";
  } else if (scrollY === 0) {
    target.style.position = "initial";
    target.style.zIndex = "initial";
  }
}

function handleScrollTopButton() {
  const button = document.getElementById("ur-scroll-top");
  if (window.pageYOffset >= 20 && button) {
    button.style.display = "block";
  } else if (window.pageYOffset < 20 && button) {
    button.style.display = "none";
  }
}

function addMobileScrollHandler() {
  window.mobileScrollHander = throttle(() => {
    handleScrollTopButton();
    stickMobileIndexHeader();

    if (window.location.pathname.indexOf("category") !== -1) {
      stickMobileStoreTab();
    } else if (window.location.pathname.indexOf("productList") !== -1) {
      stickMobileFilterBar();
    }
  }, 100);
  window.addEventListener("scroll", window.mobileScrollHander, false);
}

function removeMobileScrollHandler() {
  window.removeEventListener("scroll", window.mobileScrollHander, false);
}

function stickDesktopHeader() {
  const header = document.getElementById("desktop-header");
  const filterBar = document.getElementById("ur-filter-bar");

  if (!header) {
    return;
  }

  if (header.offsetTop < window.scrollY) {
    header.style.position = "fixed";
    header.style.top = "0px";
    header.style.width = "100%";
    if (filterBar) {
      filterBar.style.position = "fixed";
      filterBar.style.top = "65px";
      filterBar.style.width = "100%";
    }
  } else {
    header.style.position = "initial";
    if (filterBar) {
      filterBar.style.position = "initial";
    }
  }
}

function addDesktopScrollerHandler() {
  window.desktopScrollerHandler = throttle(() => {
    stickDesktopHeader();
    smallCategoryListScrollListener();
    handleScrollTopButton();
  }, 100);
  window.addEventListener("scroll", window.desktopScrollerHandler, false);
}

function removeDesktopScrollHandler() {
  window.removeEventListener("scroll", window.desktopScrollerHandler, false);
}

function resizeHandler() {
  if (window.innerWidth <= 992) {
    addMobileScrollHandler();
    removeDesktopScrollHandler();
  } else {
    removeMobileScrollHandler();
    addDesktopScrollerHandler();
  }
}

function addWindowResizeLister() {
  window.resizeHandler = throttle(resizeHandler, 100);
  window.addEventListener("resize", window.resizeHandler);
}

export default function initGlobalScrollEvent() {
  if (window.innerWidth <= 992) {
    addMobileScrollHandler();
  } else {
    addDesktopScrollerHandler();
  }
  addWindowResizeLister();
}
