/**
 * 確認 match url 是否為想要的頁面
 * @param {Object} match react router's match
 * @param {String} word the route you want to confirm
 * @returns {Boolean} returns if passing word is matching url
 */
export const checkIsIncludesInUrl = (match, word) => {
  if (!match) return false;
  return match.url.toLowerCase().includes(word.toLowerCase());
};
