import {getStoreTitle} from "@shared/appHelpers";
import React from "react";
import {Link} from "react-router-dom";
import MessagerIcon from "./MessagerIcon";
import StoreLogo from "./StoreLogo";

export default function IndexHeader(props) {
  const {match, backgroundColor} = props;

  return (
    <div id="ur-fixed-header" className="ur-page-header__fixed" style={{backgroundColor}}>
      <Link to={`/${match.params.storeName}/search`} className="ur-page-header__icon left">
        <i className="material-icons" style={{color: "#fff", fontSize: "28px"}}>
          search
        </i>
      </Link>
      <h1
        className="ur-page-header__title"
        style={{
          fontFamily: `"Nunito", "Noto Sans TC", "Microsoft JhengHei", Helvetica, sans-serif`,
        }}
      >
        <Link to={`/${match.params.storeName || "default"}`}>
          {match.params.storeName !== "default" && <StoreLogo storeName={match.params.storeName} />}
        </Link>
        {getStoreTitle(match.params.storeName)}
      </h1>
      <MessagerIcon className="ur-page-header__icon right" color="white" />
    </div>
  );
}
