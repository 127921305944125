import React from "react";
import {connect} from "react-redux";
import {NavLink, withRouter} from "react-router-dom";
import ChartBadge from "../ChartBadge/index";

function Navigation(props) {
  const {finalStore, rootIds, totalQtyCountInCart, location} = props;
  if (
    location.pathname.indexOf("/checkout") !== -1 ||
    location.pathname.indexOf("/register") !== -1 ||
    location.pathname.indexOf("/login") !== -1 ||
    location.pathname.indexOf("/familyOn") !== -1 ||
    location.pathname.indexOf("/linecoupon") !== -1 ||
    location.pathname.indexOf("/nutrendMonth") !== -1 ||
    location.pathname.indexOf("/marketing") !== -1
  ) {
    return null;
  }

  let rootId = rootIds.default;
  if (finalStore === "proteinshop") {
    rootId = rootIds.proteinshop;
  } else if (finalStore === "morningshop") {
    rootId = rootIds.morningshop;
  }

  return (
    <nav className="ur-tabnav" id="ur-navigation">
      <ul className="ur-tabnav__nav">
        <li className="ur-tabnav__nav-item">
          <NavLink exact to={`/${finalStore}`} activeClassName="ur-tabnav__selected">
            <i className="material-icons ur-tabnav__icon">home</i>
            <span className="ur-tabnav__icon-title">首頁</span>
          </NavLink>
        </li>
        {/* categoris with default id anxd filters */}
        <li className="ur-tabnav__nav-item">
          <NavLink to={`/${finalStore}/category/${rootId || ""}`} activeClassName="ur-tabnav__selected">
            <i className="material-icons ur-tabnav__icon">menu</i>
            <span className="ur-tabnav__icon-title">分類</span>
          </NavLink>
        </li>

        <li className="ur-tabnav__nav-item">
          <NavLink to="/cart" activeClassName="ur-tabnav__selected" style={{position: "relative", display: "block"}}>
            <i className="material-icons ur-tabnav__icon">shopping_cart</i>
            <span className="ur-tabnav__icon-title">購物車</span>
            {totalQtyCountInCart > 0 && <ChartBadge>{totalQtyCountInCart}</ChartBadge>}
          </NavLink>
        </li>
        <li className="ur-tabnav__nav-item">
          <NavLink to="/wishlist" activeClassName="ur-tabnav__selected">
            <i className="material-icons ur-tabnav__icon">favorite</i>
            <span className="ur-tabnav__icon-title">收藏</span>
          </NavLink>
        </li>
        <li className="ur-tabnav__nav-item">
          <NavLink to="/customer" activeClassName="ur-tabnav__selected">
            <i className="material-icons ur-tabnav__icon">person</i>
            <span className="ur-tabnav__icon-title">我的</span>
          </NavLink>
        </li>
      </ul>
    </nav>
  );
}

const mapStateToProps = (state) => {
  return {
    totalQtyCountInCart: state.cart.totalQtyCountInCart,
    finalStore: state.appConfig.finalStore,
    rootIds: state.categories.rootIds,
  };
};

export default withRouter(
  connect(
    mapStateToProps,
    undefined,
  )(Navigation),
);
