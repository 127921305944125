import React from "react";
import empty from "../../assets/empty_cart.svg";
import notFound from "../../assets/not-found.svg";

function StaticMessage(props) {
  const {text, type, className} = props;
  let classNameStr = "ur-message__text ur-text-level-4 col-xs-12";
  if (className) {
    classNameStr = `ur-message__text${className} ur-text-level-4`;
  }
  let img;
  switch (type) {
    case "empty":
      img = empty;
      break;
    case "notfound":
      img = notFound;
      break;
    default:
      break;
  }
  return (
    <div className="ur-message row center-xs">
      {img && <img className="ur-message__img col-xs-12" src={img} alt="message" />}
      <div className={classNameStr}>{text}</div>
    </div>
  );
}

export default StaticMessage;
