import {getPromotionVersion, isPromotionClosed, updatePromotionCloseState, updatePromotionVersion} from "@storage/storage";
import React from "react";
import {connect} from "react-redux";

function updateMainContentPadding(showHavePadding) {
  const mainContent = document.getElementsByClassName("ur-main-content")[0];
  if (!mainContent) {
    return;
  }

  if (showHavePadding) {
    mainContent.style.paddingTop = "50px";
  } else {
    mainContent.style.paddingTop = "0px";
  }
}

class PromotionBar extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showPromotion: false,
    };
  }

  componentDidUpdate(prevProps) {
    const {promotionVersion, promotionMessage} = this.props;

    if (prevProps.promotionVersion !== promotionVersion || prevProps.promotionMessage !== promotionMessage) {
      const savedVersion = getPromotionVersion();
      const isAlreadyClosed = isPromotionClosed();

      if ((promotionVersion && promotionVersion !== savedVersion && promotionMessage) || isAlreadyClosed !== "true") {
        updatePromotionVersion(promotionVersion);
        updatePromotionCloseState(false);
        this.open();
      }
    }
  }

  open = () => {
    this.setState(
      {
        showPromotion: true,
      },
      () => {
        updateMainContentPadding(true);
      },
    );
  };

  close = () => {
    updatePromotionCloseState(true);

    this.setState(
      {
        showPromotion: false,
      },
      () => {
        updateMainContentPadding(false);
      },
    );
  };

  render() {
    const {showPromotion} = this.state;
    const {promotionMessage, isAppReady} = this.props;
    if (showPromotion && isAppReady) {
      return (
        <div className="row ur-promotion-bar middle-xs" id="ur-promotion">
          <div className="col-xs-11 ur-promotion-bar__text" dangerouslySetInnerHTML={{__html: promotionMessage}} />
          <div className="col-xs-1 ur-promotion-bar__close" role="presentation" onClick={this.close}>
            <i className="material-icons" style={{verticalAlign: "bottom"}}>
              close
            </i>
          </div>
        </div>
      );
    }
    return null;
  }
}

const mapStateToProps = (state) => {
  return {
    promotionMessage: state.appConfig.promotion_banner.message || "",
    promotionVersion: state.appConfig.promotion_banner.version || "",
    isAppReady: state.appConfig.isAppReady,
  };
};

export default connect(mapStateToProps)(PromotionBar);
