import {VIPCardSku} from "@shared/configs";

const {fbq} = window;
const FBPixel = {
  addToCart: (data, eventId) => {
    const contentIds = [data.sku];

    if (eventId) {
      fbq(
        "track",
        "AddToCart",
        {
          currency: "TWD",
          content_ids: contentIds,
          content_name: data.name,
          value: data.qty * data.price,
          content_type: "product",
        },
        {eventID: eventId},
      );
    } else {
      fbq("track", "AddToCart", {
        currency: "TWD",
        content_ids: contentIds,
        content_name: data.name,
        value: data.qty * data.price,
        content_type: "product",
      });
    }
  },
  purchase: (order, datas) => {
    let contentIds = [];
    let contents = [];
    Object.keys(datas).forEach((key) => {
      const data = datas[key];
      contentIds = [...contentIds, data.sku];
      contents = [
        ...contents,
        {
          id: data.sku,
          quantity: data.qty,
          item_price: data.price,
        },
      ];
    });

    // 如果商品中包含 VIP 黑卡, 再多送一個 FB 事件
    if (contentIds.indexOf(VIPCardSku) !== -1) {
      fbq("trackCustom", "vip_purchase", {
        currency: "TWD",
        value: order.revenue,
      });
    }

    fbq(
      "track",
      "Purchase",
      {
        currency: "TWD",
        value: order.revenue,
        content_ids: contentIds,
        contents,
        content_type: "product",
      },
      {eventID: order.id},
    );
  },
  viewContent: (product, eventId) => {
    if (eventId) {
      fbq(
        "track",
        "ViewContent",
        {
          content_type: "product",
          content_ids: product.sku,
          value: product.specialPrice,
          content_name: product.name,
          currency: "TWD",
        },
        {
          eventID: eventId,
        },
      );
    } else {
      fbq("track", "ViewContent", {
        content_type: "product",
        content_ids: product.sku,
        value: product.specialPrice,
        content_name: product.name,
        currency: "TWD",
      });
    }
  },
  completeRegistration: () => {
    // content_name, currency, status, value
    fbq("track", "CompleteRegistration");
  },
  initiateCheckout: () => {
    fbq("track", "InitiateCheckout");
  },
  pageview: () => {
    fbq("track", "PageView");
  },
  addToWishlist: () => {
    fbq("track", "AddToWishlist");
  },
  search: () => {
    fbq("track", "Search");
  },
};
export default FBPixel;
