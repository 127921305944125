import {getCustomerGroupId} from "@actions/vip";
import React from "react";

export default function Price({originalPrice, specialPrice, status, showDiscount, discount, isVIPLimited, hideVIPPrice, disableMargin, breakPrice}) {
  if (!originalPrice && !specialPrice) {
    return null;
  }
  const customeGroupId = getCustomerGroupId();
  if (isVIPLimited && hideVIPPrice && customeGroupId !== "vip") {
    return (
      <div className="ur-product-price__viphide" style={{margin: disableMargin ? "0" : "0.8rem 0.5rem"}}>
        特惠價
        <span
          style={{
            fontSize: "0.8rem",
            color: "#7a7a7a",
            textOverflow: "ellipsis",
            whiteSpace: "nowrap",
            maxWidth: "40%",
            display: breakPrice ? "block" : "inline-block",
          }}
        >
          VIP 請登入看價格
        </span>
      </div>
    );
  }

  const statusClass = status || "";

  return (
    <div className={`ur-product-price${statusClass}`}>
      <span className={`ur-product-price__new${statusClass}`}>{`NT$${specialPrice}`}</span>
      <del style={{display: breakPrice ? "block" : "inline-block"}} className={`ur-product-price__old${statusClass}`}>{`NT$${originalPrice}`}</del>
      {showDiscount && <span className="ur-product-price__discount">{`${discount}折`}</span>}
    </div>
  );
}
