/* eslint-disable import/prefer-default-export */
import customHistory from "../components/customHistory";

export class AppRouter {
  static instance;

  static getInstance() {
    if (!AppRouter.instance) {
      AppRouter.instance = new AppRouter(customHistory);
    }
    return AppRouter.instance;
  }

  constructor() {
    this.history = customHistory;

    customHistory.listen((location) => {
      if (
        location.pathname.indexOf("forgetpassword") === -1 &&
        location.pathname.indexOf("register") === -1 &&
        location.pathname.indexOf("login") === -1
      ) {
        // check duplicate and max length
        const lastIndex = this.historyPathnames.length - 1;
        if (this.historyPathnames[lastIndex] !== location.pathname) {
          this.historyPathnames.push(location.pathname);
        }
      }
    });
  }

  initialize() {
    // save initiali path
    this.historyPathnames = [];
    this.historyPathnames.push(customHistory.location.pathname);
  }

  getPreviousPathname() {
    const index = this.historyPathnames.length - 1;
    return this.historyPathnames[index];
  }

  goBack() {
    if (this.history.action === "POP") {
      const currentStore = window.sessionStorage.getItem("ur-final-store") ? window.sessionStorage.getItem("ur-final-store") : "default";
      this.history.push(`/${currentStore}`);
    } else {
      this.history.goBack();
    }
  }
}
