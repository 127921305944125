export default function createNormalizeError(axiosError) {
  // Map is javascrip native object, babel-polyfill
  const customError = new Error();
  if (axiosError.response) {
    // get server response, but status code is not 200
    const {data, status} = axiosError.response;
    customError.status = status;
    if (status >= 500) {
      // Server Error
      customError.message = "系統暫時無法處理您的請求，請稍後再嘗試一次";
    } else {
      customError.message = data.message;
    }
  } else if (axiosError.request) {
    // The request was made but no response was received
    // `axiosError.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    customError.status = 0;
    customError.message = "系統暫時無法處理您的請求，請稍後再嘗試一次";
  } else {
    // Something happened in setting up the request that triggered an Error
    customError.status = 0;
    customError.message = "目前伺服器人數過多或網路狀況不佳，請稍後再試";
  }

  return customError;
}
