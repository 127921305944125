import axios from "axios";
import {apiHost, bearerToken} from "./configs";
import createNormalizeError from "./createNormalizeError";
import {sendAPIerrorReport} from "./setupSentry";

class API {
  static getStoreView(storeName) {
    switch (storeName) {
      case "proteinshop":
        return "proteinshop";
      case "morningshop":
        return "morningshop";
      default:
        return "default";
    }
  }

  static defaultTimeout = 1000 * 30;

  constructor(apiEndPoint, store, version = "V1", retries = 0) {
    this.currentRetry = 1;
    this.maxRetry = retries;
    this.api = `${apiHost}/rest/${API.getStoreView(store)}/${version}${apiEndPoint}`;
    this.apiEndPoint = apiEndPoint;
    this.source = axios.CancelToken.source();

    if (store === "nostroeview") {
      this.api = apiHost + apiEndPoint;
    }

    // default global setting
    this.axiosInstance = axios.create({
      timeout: API.defaultTimeout, // default timeout is 30 seconds
      headers: {
        "X-Requested-With": "XMLHttpRequest",
        "Content-Type": "application/json",
        Authorization: `Bearer ${bearerToken}`,
      },
    });

    if (this.maxRetry > 0) {
      this.axiosInstance.interceptors.response.use(undefined, (error) => {
        if (this.currentRetry < this.maxRetry) {
          this.currentRetry += 1;
          return this.axiosInstance(error.config);
        }
        sendAPIerrorReport(error, this.apiEndPoint);
        const customError = createNormalizeError(error);
        return Promise.reject(customError);
      });
    } else {
      this.axiosInstance.interceptors.response.use(undefined, (error) => {
        sendAPIerrorReport(error, this.apiEndPoint);
        const customError = createNormalizeError(error);
        return Promise.reject(customError);
      });
    }
  }

  cancelRequest() {
    this.source.cancel(`Request ${this.api} canceled.`);
  }

  getData(params = {}) {
    return this.axiosInstance({
      url: this.api,
      method: "GET",
      params,
      cancelToken: this.source.token,
    });
  }

  getByUserToken(token, timeout) {
    return this.axiosInstance({
      url: this.api,
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: timeout || API.defaultTimeout,
    });
  }

  postData(data) {
    return this.axiosInstance({
      url: this.api,
      method: "POST",
      data,
    });
  }

  postByUserToken(token, data, timeout) {
    const payload = data || {};
    return this.axiosInstance({
      url: this.api,
      method: "POST",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: timeout || API.defaultTimeout,
      data: payload,
    });
  }

  putByUserToken(token, data, timeout) {
    const payload = data || {};
    return this.axiosInstance({
      url: this.api,
      method: "PUT",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: timeout || API.defaultTimeout,
      data: payload,
    });
  }

  putData(payload, withCredentials) {
    return this.axiosInstance({
      url: this.api,
      method: "PUT",
      data: payload,
      withCredentials,
    });
  }

  deleteByUserToken(token, timeout) {
    return this.axiosInstance({
      url: this.api,
      method: "DELETE",
      headers: {
        Authorization: `Bearer ${token}`,
      },
      timeout: timeout || API.defaultTimeout,
    });
  }
}
export default API;
