const {gtag} = window;

const gtagTrack = {
  // 搜尋頁
  viewSearch: (term) => {
    try {
      gtag("event", "view_search_results", {
        search_term: term,
      });
    } catch (error) {}
  },
  // 加入購物車
  addToCart: (cartItem) => {
    try {
      gtag("event", "add_to_cart", {
        items: [
          {
            id: cartItem.sku,
            price: cartItem.specialPrice,
            name: cartItem.name,
            quantity: cartItem.qty,
          },
        ],
      });
    } catch (error) {}
  },
  // 商品列表
  viewItemList: ({pageName, items}) => {
    try {
      const mappedItems = items.map((item) => ({
        id: item.sku,
        price: item.specialPrice,
        category: pageName,
        name: item.name,
      }));
      gtag("event", "view_item_list", {
        name: pageName,
        items: mappedItems,
      });
    } catch (error) {}
  },
  // 商品詳情頁
  viewItem: (item) => {
    try {
      gtag("event", "view_item", {
        name: item.name,
        items: [
          {
            name: item.name,
            price: item.specialPrice,
            id: item.sku,
          },
        ],
      });
    } catch (error) {}
  },
  // 購買
  purchase: ({revenu, items}) => {
    try {
      const mappedItems = Object.keys(items).map((id) => ({
        name: items[id].name,
        price: items[id].price,
        id: items[id].sku,
        quantity: items[id].qty,
      }));

      gtag("event", "purchase", {
        value: revenu,
        items: mappedItems,
      });
    } catch (error) {}
  },
};

export default gtagTrack;
