import PostcodeData from "../data/postcode.json";

export function getAddresses(addresses) {
  // default value or 2 forms
  const obj = {
    home: {
      city: "台北市", // defaulr city
      region: "中正區", // area
      street: [""],
      firstname: "",
      lastname: "",
      telephone: "",
      postcode: "",
    },
    cvs: {
      city: "請選擇超商", // city
      region: "請選擇超商", // area
      street: ["請選擇超商"],
      firstname: "",
      lastname: "",
      telephone: "",
      postcode: "",
    },
    // eslint-disable-next-line object-shorthand
    update: function update(data, key) {
      this[key] = {
        city: data.city,
        region: data.region.region, // area
        street: data.street,
        firstname: data.firstname,
        lastname: data.lastname,
        telephone: data.telephone,
        postcode: data.postcode,
      };
    },
  };

  if (!addresses) {
    return obj;
  }

  const cvsPattern = /(7-11|全家)/g;

  const len = addresses.length;

  // not find the address yet
  let findCVS = false;
  let findHome = false;

  if (len === 0) {
    // no address
    return obj;
  }

  // traverse address
  for (let i = len - 1; i >= 0; i -= 1) {
    if (findCVS && findHome) {
      break;
    }
    // check city
    if (cvsPattern.test(addresses[i].city) && !findCVS) {
      obj.update(addresses[i], "cvs");
      findCVS = true;
    } else if (!cvsPattern.test(addresses[i].city) && !findHome) {
      const {city, region} = addresses[i];
      // check data
      try {
        if (PostcodeData[city][region.region]) {
          obj.update(addresses[i], "home");
          findHome = true;
        }
      } catch (error) {}
    }
  }

  return obj;
}

/**
 *
 * @param {Object} data
 * data.lmsItems
 * data.transferItems
 * data.preorderItems
 * data.freeShippingThreshold
 */
export function processItems(data) {
  const {lmsItems, transferItems, preorderItems, freeShippingThresholds} = data;

  // 是否達到免運門檻
  let lmsDiff;

  //	小計
  let lmsSubtotal = 0;
  let transferSubtotal = 0;
  let preorderSubtotal = 0;

  // 邊長和 => 確認是否可以超取
  let lmsShortestEdge = 0;
  let lmsLongestEdge = 0;
  let lmsMidEdge = 0;
  let preorderShortestEdge = 0;
  let preorderLongestEdge = 0;
  let preorderMidEdge = 0;
  let lmsVolumn = 0;
  let preorderVolumn = 0;

  lmsItems.forEach((element) => {
    lmsSubtotal += parseInt(element.row_total, 10);

    // asending order
    let tempEdges = [element.width, element.length, element.height].map((e) => parseInt(e, 10)).sort((a, b) => a - b);

    // compute shortest edges
    tempEdges = [tempEdges[0] * element.qty, tempEdges[1], tempEdges[2]].map((e) => parseInt(e, 10)).sort((a, b) => a - b);

    if (lmsMidEdge < tempEdges[1]) {
      lmsMidEdge = tempEdges[1];
    }
    if (lmsLongestEdge < tempEdges[2]) {
      lmsLongestEdge = tempEdges[2];
    }

    lmsShortestEdge += tempEdges[0];
    lmsVolumn += tempEdges[0] * tempEdges[1] * tempEdges[2];
  });

  transferItems.forEach((element) => {
    transferSubtotal += parseInt(element.row_total, 10);
  });

  preorderItems.forEach((element) => {
    preorderSubtotal += parseInt(element.row_total, 10);

    // asending order
    let tempEdges = [element.width, element.length, element.height].map((e) => parseInt(e, 10)).sort((a, b) => a - b);

    // compute shortest edges
    tempEdges = [tempEdges[0] * element.qty, tempEdges[1], tempEdges[2]].map((e) => parseInt(e, 10)).sort((a, b) => a - b);

    if (preorderMidEdge < tempEdges[1]) {
      preorderMidEdge = tempEdges[1];
    }
    if (preorderLongestEdge < tempEdges[2]) {
      preorderLongestEdge = tempEdges[2];
    }

    preorderShortestEdge += tempEdges[0];
    preorderVolumn += tempEdges[0] * tempEdges[1] * tempEdges[2];
  });

  lmsDiff = freeShippingThresholds - lmsSubtotal > 0 ? freeShippingThresholds - lmsSubtotal : 0;

  return {
    lmsTotalsData: {
      diff: lmsDiff,
      totals: lmsSubtotal,
    },
    transferTotalsData: {
      diff: -1,
      totals: transferSubtotal,
    },
    preorderTotalsData: {
      diff: -1,
      totals: preorderSubtotal,
    },
    // sort edges again
    lmsEdges: [lmsShortestEdge, lmsMidEdge, lmsLongestEdge].sort((a, b) => a - b).concat(lmsVolumn),
    preorderEdges: [preorderShortestEdge, preorderMidEdge, preorderLongestEdge].sort((a, b) => a - b).concat(preorderVolumn),
  };
}

/**
 *
 * @param {Object} data
 * data.constrains: cvs constrains
 * data.itemArray: [ processedItems.edges ]
 */
export function checkCVSConstrain(data) {
  const {constrains, itemData} = data;

  return (
    itemData.lmsEdges[0] <= parseInt(constrains.sedge, 10) &&
    itemData.lmsEdges[1] <= parseInt(constrains.medge, 10) &&
    itemData.lmsEdges[2] <= parseInt(constrains.ledge, 10) &&
    itemData.lmsEdges[3] <= parseInt(constrains.volumn, 10) &&
    itemData.preorderEdges[0] <= parseInt(constrains.sedge, 10) &&
    itemData.preorderEdges[1] <= parseInt(constrains.medge, 10) &&
    itemData.preorderEdges[2] <= parseInt(constrains.ledge, 10) &&
    itemData.preorderEdges[3] <= parseInt(constrains.volumn, 10)
  );
}

export function normalizeCouponList(couponListFromApi) {
  return couponListFromApi.map((coupon) => ({
    couponCode: coupon.coupon_code,
    description: coupon.description,
    destinationLink: coupon.destination_link,
    effectiveDate: coupon.effective_date,
    name: coupon.name,
    ruleId: coupon.rule_id,
    storeLabel: coupon.store_label,
    usableCount: coupon.used_count,
    usedCount: coupon.used_count,
  }));
}

export function filterAppliedCustomerCoupon(appliedCouponCode, normalizedCustomerCoupon) {
  const checkedAppliedCouopn = normalizedCustomerCoupon.filter((customerCouopn) => customerCouopn.couponCode === appliedCouponCode);
  return !!checkedAppliedCouopn[0];
}
