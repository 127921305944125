import {captureSentryException} from "@shared/setupSentry";
import isEmail from "validator/lib/isEmail";

export default function checkCustomerDataSchema(customerData) {
  let valid = false;
  try {
    if (
      customerData &&
      Array.isArray(customerData.addresses) &&
      Array.isArray(customerData.custom_attributes) &&
      isEmail(customerData.email) &&
      customerData.firstname &&
      customerData.lastname &&
      customerData.lastUpdatedTime &&
      customerData.id &&
      customerData.groupId
    ) {
      valid = true;
    }
  } catch (error) {
    captureSentryException(error);
  }

  return valid;
}
