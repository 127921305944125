import {checkAdultContentPermission} from "@actions/adultContent";
import {fetchAppData, getSDK, getTappaySDK, setFinalStore} from "@actions/appConfig";
import {getWishlistFromLocalAction} from "@actions/wishlist";
import loadable from "@loadable/component";
import {AppRouter} from "@shared/AppRouter";
import {checkFinalStore} from "@shared/checkFinalStore";
import initGlobalScrollEvent from "@shared/globalScrollListener";
import CRMInsider from "@shared/insder";
import iterableAnalytics from "@shared/iterableAnalytics";
import parseAndSaveUtmParams from "@shared/parseAndSaveUtmParams";
import {setEcommerceId} from "@storage/ecommerceId";
import React, {Component} from "react";
import {Redirect, Route, Router, Switch} from "react-router-dom";
import store from "../store";
import "../styles/_app.scss";
import customHistory from "./customHistory";
import InfoPage from "./Footer/InfoPage";
import addPromotionEventListener from "./GA/PromotionEvent";
import Loading from "./Loading/index";
import NotFound from "./NotFound";
import Page from "./Page/Page";

const CustomerPage = loadable(() => import(/* webpackChunkName: "customer" */ "./CustomerPage"), {
  fallback: <Loading />,
});

const CouponBindingPage = loadable(() => import("./CouponPage/CouponBindingPage"));
const CouponPromotionPage = loadable(() => import("./CouponPage/CouponPromotionPage"));

const CheckoutPage = loadable(() => import(/* webpackChunkName: "checkout" */ "./CheckoutPage"), {
  fallback: <Loading />,
});
const ProductPage = loadable(() => import(/* webpackChunkName: "product" */ "./ProductPage"), {
  fallback: <Loading />,
});
const ProductListPage = loadable(() => import(/* webpackChunkName: "productlist" */ "./ProductListPage"), {
  fallback: <Loading />,
});
const Homepage = loadable(() => import(/* webpackChunkName: "home" */ "./Homepage"), {
  fallback: <Loading />,
});
const CartPage = loadable(() => import(/* webpackChunkName: "cart" */ "./CartPage"), {
  fallback: <Loading />,
});
const WishlistPage = loadable(() => import(/* webpackChunkName: "wishlist" */ "./WishlistPage"), {
  fallback: <Loading />,
});

const CategoryPage = loadable(() => import(/* webpackChunkName: "category" */ "./CategoryPage"), {
  fallback: <Loading />,
});

const MarketingPage = loadable(() => import("./MarketingPage/MarketingPage"), {
  fallback: <Loading />,
});
const MarketingPage2 = loadable(() => import("./MarketingPage/MarketingPage2"), {
  fallback: <Loading />,
});
const SnoopyPage = loadable(() => import("./MarketingPage/SnoopyPage"), {
  fallback: <Loading />,
});
const NutrendPromotion = loadable(() => import("./MarketingPage/NutrendPromotion"), {
  fallback: <Loading />,
});

const SearchPage = loadable(() => import(/* webpackChunkName: "search" */ "./Search/SearchPage"));
const LineBindingPage = loadable(() => import(/* webpackChunkName: "lineBinding" */ "./LineBindingPage"));
const ReviewPage = loadable(() => import("./Review/ReviewPage"));

class App extends Component {
  componentDidMount() {
    store.dispatch(fetchAppData());
    store.dispatch(getSDK());
    store.dispatch(getTappaySDK());

    store.dispatch(checkAdultContentPermission());

    store.dispatch(getWishlistFromLocalAction());

    CRMInsider.initialize();

    const finalStore = checkFinalStore();
    store.dispatch(setFinalStore(finalStore));

    if (window.sessionStorage) {
      window.sessionStorage.setItem("ur-final-store", finalStore);
    }
    AppRouter.getInstance().initialize();
    initGlobalScrollEvent();
    addPromotionEventListener();
    /**
     * set ecid or sbid in cookies
     */
    setEcommerceId(window.location.search);

    /**
     * if there are utm parameters, save them
     */
    parseAndSaveUtmParams();

    // set line line landing
    window.lineLandingPage = window.location.href;
    // init iterable
    iterableAnalytics.init();
  }

  render() {
    const finalStore = checkFinalStore();

    return (
      <Router history={customHistory}>
        <Page>
          <Switch>
            <Route
              exact
              path="/"
              render={(props) => {
                return <Redirect {...props} to={`/${finalStore}${window.location.search}`} />;
              }}
            />
            <Route exact path="/:storeName(default|morningshop|proteinshop)?" component={Homepage} />
            <Route path="/:storeName(default|morningshop|proteinshop)?/category/:topLevelId(\d+)?" component={CategoryPage} />
            <Route path="/:storeName(default|morningshop|proteinshop)?/productList/:secondLevelId(\d+)" component={ProductListPage} />
            <Redirect from="/catalog/category/view/id/:secondLevelId" to="/default/productList/:secondLevelId(\d+)" />
            <Route path="/:storeName(default|morningshop|proteinshop)?/brandList/:brandId(\d+)" component={ProductListPage} />
            <Route path="/:storeName(default|morningshop|proteinshop)?/product/:itemId(\d+)" component={ProductPage} />
            <Route path="/:storeName(default|morningshop|proteinshop)?/search" component={SearchPage} />
            <Route path="/:storeName(default|morningshop|proteinshop)?/product/reviews/:productId" component={ReviewPage} />
            <Route path="/cart" component={CartPage} />
            <Route
              path="/linecoupon/:ruleId"
              render={(props) => {
                return <LineBindingPage {...props} />;
              }}
            />
            <Route
              path="/claimCoupons/:ruleId"
              render={(props) => {
                return <CouponBindingPage {...props} />;
              }}
            />
            <Route
              path="/promotionCoupon"
              render={(props) => {
                return <CouponPromotionPage {...props} />;
              }}
            />
            <Route
              path="/checkout"
              render={(props) => {
                return <CheckoutPage {...props} />;
              }}
            />
            <Route
              path="/customer"
              render={(props) => {
                return <CustomerPage {...props} />;
              }}
            />
            <Route path="/wishlist" component={WishlistPage} />

            <Route path="/default/about" render={(props) => <InfoPage {...props} type="about" />} />
            <Route path="/default/privacy-policy" render={(props) => <InfoPage {...props} type="privacy" />} />
            <Route path="/default/purchase-information" render={(props) => <InfoPage {...props} type="purchase" />} />
            <Route path="/default/member-rights" render={(props) => <InfoPage {...props} type="member" />} />
            <Route path="/default/customer-service" render={(props) => <InfoPage {...props} type="customerService" />} />
            <Route path="/default/shipping-information" render={(props) => <InfoPage {...props} type="purchase" />} />
            <Route path="/default/return-information" render={(props) => <InfoPage {...props} type="purchase" />} />
            <Route path="/default/nutrendMonth" component={NutrendPromotion} />
            <Route path="/default/marketing/peanuts70" component={SnoopyPage} />
            <Route path="/:storeName(default|morningshop|proteinshop)?/marketing/:pageId(\d+)" component={MarketingPage} />
            <Route exact path="/default/opening" component={MarketingPage2} />
            <Route component={NotFound} />
          </Switch>
        </Page>
      </Router>
    );
  }
}

export default App;
