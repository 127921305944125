import {checkIsIncludesInUrl} from "@shared/checkFunction";
import React, {useEffect, useRef, useState} from "react";
import Tracking from "../GA/Tracking";
import SmallProductCard from "./SmallProductCard";

export default function HorizatalScrollMenu({
  products,
  storeName,
  productIds,
  isLimited,
  title,
  adultContentPermission,
  childrenComponent,
  match,
  forceToShowArrow, // productList icon 架構不太一樣，props的data以length做基準，一個頁面大於12時做強制顯示(Web)，App不受影響
}) {
  const [arrowInitialized, setArrowInitialized] = useState(false);

  let transformedProducts = products;
  if (Array.isArray(products)) {
    transformedProducts = {};
    products.forEach((element) => {
      transformedProducts[element.id] = element;
    });
  }

  const currentProducts = productIds && productIds.length > 0 ? productIds.map((id) => transformedProducts[id]) : [];

  const scrolledContent = useRef(null);
  const arrowRight = useRef(null);
  const arrowLeft = useRef(null);

  let scrollContentWidth = 0;
  let moveDistance = 0;
  let lastChildLeft = 0;

  function moveToRight() {
    scrollContentWidth = scrolledContent.current.clientWidth;
    moveDistance += scrollContentWidth;
    getLastChildLeft();
    arrowLeft.current.style.display = "block";
    arrowRight.current.style.display = lastChildLeft - moveDistance < scrollContentWidth ? "none" : "block";
    scrolledContent.current.style.cssText = `transform: translate(-${moveDistance}px, 0); transition: transform 0.5s`;
  }

  function moveToLeft() {
    scrollContentWidth = scrolledContent.current.clientWidth;

    moveDistance -= scrollContentWidth;
    if (moveDistance > 0) {
      arrowRight.current.style.display = "block";
      arrowLeft.current.style.display = "block";
    } else {
      moveDistance = 0; // 確保點到最後一定為0
      arrowLeft.current.style.display = "none";
      arrowRight.current.style.display = "block";
    }
    scrolledContent.current.style.cssText = `transform: translate(-${moveDistance}px, 0); transition: transform 0.5s`;
  }

  let isGetDom = false;

  function initializeScroll() {
    if (scrolledContent && scrolledContent.current) {
      getLastChildLeft();
      if (lastChildLeft >= scrollContentWidth || forceToShowArrow) {
        arrowRight.current.style.display = "block";
        setArrowInitialized(true);
      }
    }
  }

  function getLastChildLeft() {
    if (scrolledContent && scrolledContent.current) {
      isGetDom = true;
      const {lastChild} = scrolledContent.current;
      if (isGetDom && lastChild) {
        scrollContentWidth = scrolledContent.current.clientWidth;
        lastChildLeft = parseInt(lastChild.getBoundingClientRect().left, 10);
      }
    }
  }

  useEffect(() => {
    if (window.innerWidth >= 992 - 15 && !arrowInitialized) {
      initializeScroll();
    }
  }, []);

  const childrenItems =
    childrenComponent ||
    currentProducts.map((product) => (
      <li className="col-xs-6-5 col-sm-4 col-md-3 col-lg-2" key={product.id}>
        <SmallProductCard
          key={product.id}
          storeName={storeName}
          product={product}
          adultContentPermission={adultContentPermission}
          isLimited={isLimited}
          categoryName={title}
        />
      </li>
    ));

  return (
    <div className="ur-horizantal-scroll-wrapper">
      <Tracking title={title} list="Home page" productList={currentProducts} doNotSendPageview />
      <button type="button" onClick={moveToLeft} className="ur-horizantal-scroll-wrapper__arrow-left" ref={arrowLeft}>
        <i className="material-icons">keyboard_arrow_left</i>
      </button>
      <div className="ur-horizantal-scroll-wrapper__content">
        <ul className={checkIsIncludesInUrl(match, "productList") ? "" : "row ur-no-flex-wrap"} ref={scrolledContent}>
          {childrenItems}
        </ul>
      </div>
      <button type="button" className="ur-horizantal-scroll-wrapper__arrow-right" onClick={moveToRight} ref={arrowRight}>
        <i className="material-icons">keyboard_arrow_right</i>
      </button>
    </div>
  );
}
