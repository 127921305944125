// @flow
import React from "react";
import {Link} from "react-router-dom";
import iterableAnalytics from "@shared/iterableAnalytics";

type Props = {
  storeName: string,
  searchTextsData: Array<string>,
};

export default function SearchTextsSection(props: Props) {
  const {searchTextsData, storeName, type} = props;
  const className = type ? `ur-search__text__${type}` : "col-xs-4 col-sm-3 ur-search__text";
  const wrapper = type ? `ur-search__wrapper__${type} col-xs-12 row top-xs` : "ur-search__text__wrapper col-xs-12 row top-xs";

  if (searchTextsData === "undefined" || searchTextsData === " " || !searchTextsData) {
    return null;
  }

  return (
    <div className={wrapper}>
      {searchTextsData.slice(0, 10).map((text, index) => {
        if (text === "undefined" || text === "null" || text === " " || !text) {
          return null;
        }
        return (
          <Link
            to={`/${storeName || "default"}/search?q=${text}`}
            className={className}
            key={index}
            onClick={() => iterableAnalytics.recommendSearch(text)}
          >
            {text}
          </Link>
        );
      })}
    </div>
  );
}
