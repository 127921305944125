import APIEndpoints from "@shared/APIEndPoints";

const apiEndPointsAndMessgae = [
  // customer
  [APIEndpoints.tokenExchange, "用戶 Token 交換"],
  [APIEndpoints.login, "用戶登入"],
  [APIEndpoints.socialLogin, "Facebook登入"],
  [APIEndpoints.register, "用戶註冊"],
  [APIEndpoints.updateCustomerInfo, "修改用戶資訊"],
  [APIEndpoints.otpSend, "寄送簡訊驗證碼"],
  [APIEndpoints.otpVerifyed, "驗證簡訊驗證碼"],
  [APIEndpoints.forgetPassword, "忘記密碼帳號驗證"],
  [APIEndpoints.resetPassword, "重設密碼"],
  [APIEndpoints.verifyResetPasswordAcoouont, "重設密碼帳號檢查"],
  [APIEndpoints.cartId, "取得購物車Id"],
  [APIEndpoints.chekcoutConfig, "取得購物車資訊"],
  [APIEndpoints.cart, "更新購物車"],
  // 結帳
  [APIEndpoints.paymentInfo, "建立訂單"],
  [APIEndpoints.shippingInfo, "送貨資訊"],
];

// Map is javascrip native object, babel-polyfill
export const apiEndPointsMap = new Map(apiEndPointsAndMessgae);

export function getErrorMessageTitle(currentApiEndPoint) {
  if (currentApiEndPoint.includes(APIEndpoints.cart)) {
    return apiEndPointsMap.get(APIEndpoints.cart); // => 更新購物車
  }
  if (apiEndPointsMap.get(currentApiEndPoint)) {
    return apiEndPointsMap.get(currentApiEndPoint); // => 重設密碼, 購物車Id...etc
  }
  return "API Error";
}

export function getRequestPayload(payload) {
  // if payload contains password, extrach username only
  if (payload && payload.includes("password")) {
    const withoutPasswordPayload = JSON.parse(payload);
    delete withoutPasswordPayload.password;
    return withoutPasswordPayload;
  }
  return payload;
}

export function retriveUserEmailOrPhoneFromPaylod(payload) {
  if (!payload) {
    return "unknown";
  }
  const payloadJSON = JSON.parse(payload);
  if (payload.includes("username")) {
    return payloadJSON.username;
  }
  if (payload.includes("themecafeMobilenumber")) {
    return payloadJSON.themecafeMobilenumber;
  }
  if (payload.includes("email")) {
    return payloadJSON.email;
  }
  return "unknown";
}
