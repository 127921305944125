export default function checkAppConfigSchema(appConfigData) {
  // required keys: ["cvs_constrain", "promotion_banner", "hot_sale_id", "shipping_threshold", "vendor_of_shipping", "lastUpdatedTime"],

  let valid = false;
  if (
    typeof appConfigData.cvs_constrain === "object" &&
    Array.isArray(appConfigData.brands) &&
    Array.isArray(appConfigData.shipping_threshold) &&
    Array.isArray(appConfigData.vendor_of_shipping) &&
    appConfigData.promotion_banner &&
    appConfigData.lastUpdatedTime
  ) {
    valid = true;
  }

  return valid;
}
