import {createBrowserHistory} from "history";
import {wrapHistory} from "oaf-react-router";

const customHistory = createBrowserHistory();

const oafSetting = {
  primaryFocusTarget: "body",
};
wrapHistory(customHistory, oafSetting);

export default customHistory;
