import React from "react";
import {useHistory} from "react-router-dom";

export default function ModalHeader(props) {
  const {clickHandler, title} = props;
  const history = useHistory();
  const clickHandlerAction = () => {
    if (clickHandler) {
      clickHandler();
    } else {
      history.goBack();
    }
  };
  return (
    <>
      <h1 className="ur-page-header__title col-xs-12">{title}</h1>
      <div onClick={clickHandlerAction} className="ur-page-header__icon right" role="button" tabIndex={0}>
        <i className="material-icons">close</i>
      </div>
    </>
  );
}
