import {LocalStorage} from "./LocalStorage";
import {LOCAL_CART_KEY, USER_CART_ID} from "./storageKeyName";

const cartKey = LOCAL_CART_KEY;

export const getLocalCart = () => {
  try {
    const oldCart = LocalStorage.getItem(cartKey);
    if (!oldCart) {
      // set empty cart in localstorage
      LocalStorage.setItem(cartKey, "{}");
      return {};
    }
    return JSON.parse(oldCart);
  } catch (error) {
    return {};
  }
};

export const addItemToLocalCart = (itemData) => {
  try {
    let oldCart = LocalStorage.getItem(cartKey);

    if (!oldCart) {
      // creat local storage
      LocalStorage.setItem(cartKey, "{}");
      oldCart = {};
    } else {
      oldCart = JSON.parse(oldCart);
    }

    const itemId = itemData.item_id;
    let newCart = {};

    if (oldCart[itemId]) {
      // merge
      newCart = {
        ...oldCart,
        [itemId]: {
          ...oldCart[itemId],
          qty: oldCart[itemId].qty + itemData.qty,
          row_total_with_discount: (oldCart[itemId].qty + itemData.qty) * itemData.specialPrice,
        },
      };
    } else {
      newCart = {
        ...oldCart,
        [itemId]: {
          ...itemData,
          row_total_with_discount: itemData.qty * itemData.specialPrice,
        },
      };
    }

    LocalStorage.setItem(cartKey, JSON.stringify(newCart));
    return newCart;
  } catch (error) {
    return false;
  }
};

export const modifyItemToLocalCart = (itemData) => {
  try {
    let oldCart = LocalStorage.getItem(cartKey);
    const itemId = itemData.item_id;
    let newCart = {};

    oldCart = JSON.parse(oldCart);

    if (oldCart[itemId]) {
      newCart = {
        ...oldCart,
        [itemId]: {
          ...oldCart[itemId],
          qty: parseInt(itemData.qty, 10),
          row_total_with_discount: parseInt(itemData.qty, 10) * oldCart[itemId].specialPrice,
        },
      };
      LocalStorage.setItem(cartKey, JSON.stringify(newCart));
      return newCart;
    }
    return oldCart;
  } catch (error) {
    return false;
  }
};
export const deleteItemFromLocalCart = (itemData) => {
  try {
    const oldCart = LocalStorage.getItem(cartKey);
    const itemId = itemData.item_id;
    const newCart = JSON.parse(oldCart);
    delete newCart[itemId];

    LocalStorage.setItem(cartKey, JSON.stringify(newCart));

    return newCart;
  } catch (error) {
    return false;
  }
};

export const clearLocalCart = () => {
  try {
    LocalStorage.removeItem(cartKey);
  } catch (error) {}
};

export function saveLocalCartId(cartId) {
  try {
    LocalStorage.setItem(USER_CART_ID, cartId);
  } catch (error) {}
}

export function getLocalCartId() {
  try {
    const localCartId = LocalStorage.getItem(USER_CART_ID);
    return localCartId;
  } catch (error) {
    return null;
  }
}
