/** UI constansts */
export const PROTEIN_SHOP = "proteinshop";
export const MORNING_SHOP = "morningshop";
export const URMART_SHOP = "default";
export const INDEX = "index";
export const CATEGORY = "category";
export const PRODUCT = "product";
export const MODAL = "modal";
export const PRODUCT_LIST = "productList";
export const MARKETIG = "marketing";
export const COUPON_TEXT = "折價券";
export const ACTIVE_COUPON_TYPE = "active";
export const USED_COUPON_TYPE = "used";
export const EXPIRED_COUPONE_TYPE = "expired";
export const COUPON = "coupon";
