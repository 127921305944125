import {websitePublicURL} from "@shared/configs";
import axios from "axios";
import React, {useEffect, useState} from "react";
import Media from "react-media";
import {useSelector, useDispatch} from "react-redux";
import {visitEventSent} from "@actions/global";
import iterableAnalytics from "@shared/iterableAnalytics";
import "../../styles/infostyle.css";
import PageHeader from "../PageHeader/index";
import MobileFooter from "./MobileFooter";

export default function InfoPage({type}) {
  const {isAppReady} = useSelector((state) => state.appConfig);
  const {isVisitEventSent} = useSelector((state) => state.global);
  const dispatch = useDispatch();

  const [infoHtml, setInfoHtml] = useState("");
  const requestInfoHtml = (type) => {
    let infoUrl;
    switch (type) {
      case "about":
        infoUrl = `${websitePublicURL}/about.html`;
        break;
      case "privacy":
      case "member":
        infoUrl = `${websitePublicURL}/member-policy.html`;
        break;
      case "purchase":
        infoUrl = `${websitePublicURL}/company-policy.html`;
        break;
      case "customerService":
        infoUrl = `${websitePublicURL}/customer-service.html`;
        break;
      default:
        infoUrl = "";
    }
    if (infoUrl) {
      axios
        .get(infoUrl)
        .then((response) => {
          setInfoHtml(response.data);
        })
        .catch(() => {
          setInfoHtml("<p></p>");
        });
    }
  };
  useEffect(() => requestInfoHtml(type), [type]);

  useEffect(() => {
    if (isAppReady && !isVisitEventSent) {
      iterableAnalytics.visit(window.location.href);
      dispatch(visitEventSent());
    }
  }, [isAppReady, isVisitEventSent]);

  return (
    <React.Fragment>
      <PageHeader title="優馬選品" />
      <div dangerouslySetInnerHTML={{__html: infoHtml}} />
      <Media query="(max-width: 991px)">{(isMatches) => (isMatches ? <MobileFooter background="ur-background-grey" /> : null)}</Media>
    </React.Fragment>
  );
}
