export const APP_CONFIG = "ur-appconfig";

export const ECID_KEY = "ur-ecid";
export const SBID_KEY = "ur-shopbackid";

export const LOCAL_CART_KEY = "urmart-cart";
export const TOKEN_KEY = "ur-ctoken";

export const PROMOTION_VERSION = "ur-promotion-version";
export const PROMOTION_CLOSE = "ur-promotion-close";

export const ADULT_CONTENT = "is_adult_temp";
export const LINE_BINDING_STATE = "ur-line-binding-state";
export const CUSTOMER_DATA = "ur-customer";
export const SEARCH_HISTORY = "ur-search-history";
export const CAN_CASH = "ur-can-cash";

// 給 ematic & optimonk 用的
export const IS_LOGON = "ur-isLogin";

export const WIHSLIST_PRODUCTS = "ur-wishlist-products";

/**
 * Attention:
 * DO NOT change the following keynames cause it was used by CRM also,
 * if you want to change, please do the confrimation with PM and BD team
 */
export const CHECKOUT_METHOD = "ur-checkout-method";
export const CHECKOUT_SHIPPING_AMOUNT = "ur-checkout-shipping";
export const CHECKOUT_REVENUE = "ur-checkout-revenue";
export const CHECKOUT_ITEMS = "ur-checkout-itemData";
export const CHECKOUT_ORDER_ID = "ur-checkout-payment-oid";
export const IS_CHECKOUT_SUCCESS = "ur-checkout-is-payment-success";
export const CHECKOUT_DISCOUNT = "ur-checkout-total";
export const CHECKOUT_COUPON = "ur-checkout-coupon";

export const FINAL_STORE = "ur-final-store";

/**
 * utm key
 */
export const UTM_PARAMS = "ur-utm";
export const USER_CART_ID = "ur-cardId";

/**
 * Cache products , for iterable tracking
 */

export const PRODUCTS_CACHE = "ur-products-cache";
