import CircularProgress from "@material-ui/core/CircularProgress";
import {withStyles} from "@material-ui/styles";
import React from "react";

const ColorCircularProgress = withStyles({
  root: {
    color: "#5c6ac4",
  },
})(CircularProgress);

export default function Loading({type}) {
  return type === "overlay" ? (
    <div className="ur-loading-wrapper__overlay">
      <ColorCircularProgress />
    </div>
  ) : (
    <div className="ur-loading-wrapper">
      <ColorCircularProgress />
    </div>
  );
}
