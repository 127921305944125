import {clearUserData} from "@actions/auth";
import {showMessage} from "@actions/global";
import store from "../store";

/**
 * Global error handler for axios
 */

function errorHandler(error, isShow, showActionButton) {
  const errorStatus = error.status;
  const errorMessgae = error.message;
  store.dispatch(showMessage("error", errorMessgae, isShow, showActionButton));

  if (errorStatus === 401) {
    store.dispatch(showMessage("error", "您已被登出，請重新登入", isShow, showActionButton));
    store.dispatch(clearUserData());
  }
}

export default errorHandler;
