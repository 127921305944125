import CircularProgress from "@material-ui/core/CircularProgress";
import {withStyles} from "@material-ui/styles";
import React from "react";
import {connect} from "react-redux";

const ColorCircularProgress = withStyles({
  root: {
    color: "#5c6ac4",
  },
})(CircularProgress);

function GlobalLoading({showLoading}) {
  return showLoading ? (
    <div className="ur-loading-wrapper__overlay">
      <ColorCircularProgress color="#5c6ac4" />
    </div>
  ) : null;
}

const mapStateToProps = (state) => {
  return {
    showLoading: state.global.showLoading,
  };
};

export default connect(mapStateToProps)(GlobalLoading);
