import {AppRouter} from "@shared/AppRouter";
import React from "react";
import {Link} from "react-router-dom";
import StoreLogo from "./StoreLogo";

export default function DefaultHedaer(props) {
  const {match, title} = props;
  return (
    <>
      <div className="ur-page-header__icon left" onClick={() => AppRouter.getInstance().goBack()} role="button" tabIndex={0}>
        <i className="material-icons">navigate_before</i>
      </div>
      <h1 className="ur-page-header__title col-xs-12">
        <Link to={`/${match.params.storeName || "default"}`}>
          <StoreLogo storeName={match.params.storeName} />
        </Link>
        {title}
      </h1>
    </>
  );
}
