import {morningshopLogo, proteinshopLogo, urmartLogo} from "@shared/defaultIcons";
import React from "react";
import {MORNING_SHOP, PROTEIN_SHOP} from "../../types/typeConstants";

const getStoreLogo = (storeName) => {
  switch (storeName) {
    case PROTEIN_SHOP:
      return proteinshopLogo;
    case MORNING_SHOP:
      return morningshopLogo;
    default:
      return urmartLogo;
  }
};

export default function StoreLogo({storeName}) {
  return <img src={getStoreLogo(storeName)} className="ur-store-logo" alt="UrMart 優馬選品" />;
}
