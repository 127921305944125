import React from "react";
import HotSaleList from "../CustomerPage/Dashboard/HotSaleList";
import {StaticMessage} from "../Message";
import PageHeader from "../PageHeader/index";

const NotFound = () => {
  return (
    <div className="ur-page">
      <PageHeader type="index" />
      <StaticMessage text="頁面不存在，請確認連結網址是否正確，或嘗試其他活動與類別" type="notfound" />
      <HotSaleList />
    </div>
  );
};
export default NotFound;
