import moment from "moment";

/**
 * Compute product discount
 * @param {Object} product
 * @param {number} product.specialPrice special price
 * @param {number} product.originalPrice original price
 */
export function computeDiscount({specialPrice, originalPrice}) {
  let discount = -1;

  if (specialPrice < originalPrice) {
    discount = (specialPrice * 100) / originalPrice;
  }

  if (discount > 0) {
    discount = Math.round(discount);
    if (discount % 10 === 0) discount /= 10;
  }

  return discount;
}

/**
 * Check if producut is on sale by specail_from_date, special_to_date
 * @param {Object}
 * @property {string} fromDate
 * @property {string} toDate
 */
export function checkOnSale({fromDate, toDate}) {
  let specialFromDate = fromDate || "";
  let specialToDate = toDate || "";
  let isOnSale = false;
  const currentDate = moment();

  if (specialFromDate && specialToDate) {
    specialFromDate = moment(specialFromDate.replace(" ", "T")).add(8, "hours");
    specialToDate = moment(specialToDate.replace(" ", "T")).add(8, "hours");

    if (currentDate.isSameOrBefore(specialToDate) && moment(currentDate).isSameOrAfter(specialFromDate)) {
      isOnSale = true;
    }
    specialFromDate = specialFromDate.format("YYYY-MM-DD HH:mm:ss");
    specialToDate = specialToDate.format("YYYY-MM-DD HH:mm:ss");
  }

  if (!specialFromDate && specialToDate) {
    specialToDate = moment(specialToDate.replace(" ", "T")).add(8, "hours");
    if (currentDate.isSameOrBefore(specialToDate)) {
      isOnSale = true;
      specialFromDate = "";
    }
    specialToDate = specialToDate.format("YYYY-MM-DD HH:mm:ss");
  }

  if (specialFromDate && !specialToDate) {
    specialFromDate = moment(specialFromDate.replace(" ", "T")).add(8, "hours");
    if (currentDate.isSameOrAfter(specialFromDate)) {
      isOnSale = true;
      specialToDate = "";
    }

    specialFromDate = specialFromDate.format("YYYY-MM-DD HH:mm:ss");
  }

  return {
    isOnSale,
    specialFromDate,
    specialToDate,
  };
}

/**
 * Retrive the value of custom_attributes
 * @param {Array} attribute name list
 * @param {Object} product api response
 * @returns {Object} attribteValues
 */
/**
 * @typedef {Object} attribteValues
 * @property {string} [attributeName]
 */
export function retriveProductCustomAttributes(product, attributeList) {
  const attributes = [...attributeList];
  const attribteValues = {};

  const customAttrObj = product.custom_attributes.reduce((accumulator, currentCustomAttr) => {
    return {
      ...accumulator,
      [currentCustomAttr.attribute_code]: currentCustomAttr.value,
    };
  }, {});
  attributes.forEach((attr) => {
    attribteValues[attr] = customAttrObj[attr];
  });

  return attribteValues;
}

/**
 * Get product's is_vip_limited value
 * 如果 group product 下無任何子商品, 則以上層 group product 的 is_vip_limited 設定為準
 * @param product
 * @return {boolean}
 */
export function getIsVIPLimitedAttributes(product) {
  // eslint-disable-next-line camelcase
  const {is_vip_limited} = retriveProductCustomAttributes(product, ["is_vip_limited"]);

  // eslint-disable-next-line camelcase
  let isVIPLimited = is_vip_limited === "1";

  // handle group product
  if (product.type_id === "grouped" && product.product_links && product.product_links.length > 0) {
    // if any of items' is_vip_limited is set to "1", then return true
    const productLinksResult = product.product_links
      .map((simpleProduct) => simpleProduct.extension_attributes.is_vip_limited)
      .some((isVIPLimitedValue) => isVIPLimitedValue === "1");

    isVIPLimited = isVIPLimited || productLinksResult;
  }

  return isVIPLimited;
}

/**
 * Get product's hide_the_price value
 * 如果 group product 下無任何子商品, 則以上層 group product 的 hide the price 設定為準
 * @param  {ProductResponse} product
 * @returns boolean hideThePrice
 */
export function getHideVIPAttributes(product) {
  // eslint-disable-next-line camelcase
  const {hide_the_price} = retriveProductCustomAttributes(product, ["hide_the_price"]); // "0" or "1"

  // eslint-disable-next-line camelcase
  let hideThePrice = hide_the_price;

  if (product.type_id === "grouped" && product.product_links && product.product_links.length > 0) {
    const productLinksResult = product.product_links
      .map((simpleProduct) => simpleProduct.extension_attributes.hide_the_price)
      .every((hideThePriceValue) => hideThePriceValue === "0");

    // reutrn "0" only if
    // 1 outer group product's hide_the_price === "0"
    // 2 all sub product's hide_the_price === "0"
    hideThePrice = hideThePrice === "0" && productLinksResult ? "0" : "1";
  }

  return hideThePrice === "1";
}

/**
 * Get simple product's special price, original price
 *
 * == If product is on sale
 *   - specialPrice = special_price
 *   - listPrice = original_price
 *
 * == If product is not on sale
 *   - specialPrice = original_price
 *   - listPrice = list_price
 *
 * @param {Object} product
 */
export function getSimpleProductPrice(product) {
  const productCustomAttr = retriveProductCustomAttributes(product, ["list_price", "special_price", "special_from_date", "special_to_date"]);
  const originalPrice = parseInt(product.price, 10);
  const listPrice = parseInt(productCustomAttr.list_price, 10) || parseInt(product.price, 10);
  const specialPriceFromApi = parseInt(productCustomAttr.special_price, 10);
  let specialPrice = parseInt(product.price, 10);
  let fromDate = productCustomAttr.special_from_date;
  let toDate = productCustomAttr.special_to_date;
  let discount = -1;

  if (specialPriceFromApi) {
    // Check if product is on sale
    const {isOnSale, specialFromDate, specialToDate} = checkOnSale({fromDate, toDate});

    // if product is on sale
    if (isOnSale) {
      specialPrice = specialPriceFromApi;
      discount = computeDiscount({specialPrice, originalPrice});
      fromDate = specialFromDate;
      toDate = specialToDate;
      return {
        originalPrice,
        specialPrice,
        discount,
        specialFromDate: fromDate,
        specialToDate: toDate,
      };
    }
  }

  return {
    originalPrice: listPrice,
    specialPrice: originalPrice,
    discount,
    specialFromDate: fromDate || "",
    specialToDate: toDate || "",
  };
}

/**
 * 找出 Group product 下所有子商品的最低價錢 (specialPrice) & 最高價錢 (originalPrice)
 * @param {Object} product
 * @returns {Object}
 * @property {number} originalPrice
 * @property {number} specialPrice
 * @property {number} discount
 * @property {string} specialFromDate - YYYY/MM/DD HH:mm:ss
 * @property {string} specialToDate   - YYYY/MM/DD HH:mm:ss
 */
export function getGroupProdcutListPrice(product) {
  //  預設回傳的 special 和 original price 皆為原價, 因 special price 有可能為空
  let specialPrice = product.extension_attributes ? parseInt(product.extension_attributes.price, 10) : 0;
  const originalPrice = product.extension_attributes ? parseInt(product.extension_attributes.price, 10) : 0;
  let specialPriceFromApi;
  let fromDate;
  let toDate;
  let discount = -1;
  const isVIPLimited = product.extension_attributes ? product.extension_attributes.is_vip_limited === "1" : false;
  const hideVIPPrice = product.extension_attributes ? product.extension_attributes.hide_the_price === "1" : false;

  try {
    // retrive necessary datat
    fromDate = product.extension_attributes.special_from_date;
    toDate = product.extension_attributes.special_to_date;
    specialPriceFromApi = product.extension_attributes.special_price;

    if (specialPriceFromApi) {
      const {isOnSale, specialFromDate, specialToDate} = checkOnSale({fromDate, toDate});
      if (isOnSale) {
        specialPrice = parseInt(specialPriceFromApi, 10);
        discount = computeDiscount({specialPrice, originalPrice});
        return {
          originalPrice,
          specialPrice,
          discount: Math.round(discount),
          specialFromDate,
          specialToDate,
          isVIPLimited,
          hideVIPPrice,
        };
      }
    }
  } catch (error) {
    // return default value
  }

  return {
    originalPrice,
    specialPrice,
    discount,
    specialFromDate: "",
    specialToDate: "",
    isVIPLimited,
    hideVIPPrice,
  };
}

export function getGroupProductPrice(products) {
  let maxPrice = 0;
  let minPrice = Infinity;
  let originalPrice;
  let maxSpecialToDate = null;
  const specialToDateArr = [];

  let minDiscount = -1;
  products.forEach((element) => {
    const {specialPrice, discount, specialToDate, originalPrice: productOriginalPrice} = getGroupProdcutListPrice(element);
    originalPrice = productOriginalPrice;
    maxPrice = Math.max(specialPrice, maxPrice);
    minPrice = Math.min(specialPrice, minPrice);
    if (specialToDate) specialToDateArr.push(moment(specialToDate));

    if (minDiscount === -1) {
      minDiscount = discount;
    }

    if (discount !== -1 && minDiscount > discount) {
      minDiscount = discount;
    }
  });

  maxSpecialToDate = moment.min(specialToDateArr).format("YYYY-MM-DD HH:mm:ss");

  return {
    originalPrice,
    specialPrice: minPrice,
    discount: minDiscount,
    specialToDate: maxSpecialToDate,
  };
}

/**
 * Mapping vendor of shipping Code (e.g "123") to meaningful string (e.g "lms")
 * @param {string} productVendorOfShippingCode - vendor of shipping code : e.g. "123"
 * @param {array} vendorOfShippingDataset - e.g [{label:"lms", value:"123"}]
 * @returns {string} - "lms" or "preorder" or "transfer"
 */
export function getVendorOfShipping(productVendorOfShippingCode, vendorOfShippingDataset) {
  const vendorOfShippings = [...vendorOfShippingDataset];
  const vendorOfShipping = vendorOfShippings.filter((element, index, currentArray) => {
    return productVendorOfShippingCode === currentArray[index].value;
  });

  return vendorOfShipping[0] ? vendorOfShipping[0].label : "lms";
}
