import React from 'react';

function CartError ({itemName}) {
    return (
        <div className='ur-message-popup__content__iconPic'>
            <i className="material-icons ur-message-popup__content__iconPic__iconRightUp">
                error
            </i>
            <i className="material-icons ur-message-popup__content__iconPic__icon">
                shopping_basket
            </i>
            <p>{itemName}</p>
        </div>
    );
}

export default CartError;