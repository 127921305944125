import firebase from "firebase";

export const initializeFirebase = () => {
  const firebaseConfig = {
    apiKey: "AIzaSyD1kOEE-rM3qhp9SR_OHcKFKICqRU8SFxA",
    authDomain: "urmart-app.firebaseapp.com",
    databaseURL: "https://urmart-app.firebaseio.com",
    projectId: "urmart-app",
    storageBucket: "urmart-app.appspot.com",
    messagingSenderId: "84131676831",
    appId: "1:84131676831:web:b3f1b1f8b349f0a5bbd8aa",
    measurementId: "G-FVDTTHG0HZ",
  };
  // Initialize Firebase
  firebase.initializeApp(firebaseConfig);
};

export const getBrowserToken = () => {
  // 先檢查瀏覽器是否支援 firebase messaging, ios, safari 皆不支援
  if (firebase.messaging.isSupported()) {
    const messaging = firebase.messaging();
    messaging.usePublicVapidKey("BMkpl-JQg-lVJXwHTj-JLt-5qOeZvjwTuO-wUbctcM5F5Iwc9C-WWAj7IEXg_mSsncmQAVO0A_dFtdoWAnN1_-w");

    // 跳出是否允許推波視窗後拿 browser token
    messaging
      .getToken()
      .then((currentToken) => {
        sessionStorage.setItem("browser-token", currentToken);
      })
      .catch(() => {
        sessionStorage.setItem("browser-token", "");
      });
  }
};

export default {};
