import {LocalStorage} from "@storage/LocalStorage";
import {UTM_PARAMS} from "@storage/storageKeyName";
import {captureSentryException} from "./setupSentry";

/**
 * @typedef {Object} utmParams
 * @property {string | Array} utmSource
 * @property {string | Array} utmMedium
 * @property {string | Array} utmCampaign
 * @property {string | Array} utmTerm
 * @property {string | Array} utmContent
 */
/**
 * @returns {utmParams}
 */
export default function getUTMParams() {
  try {
    const utmParams = JSON.parse(LocalStorage.getItem(UTM_PARAMS));
    const {utmSource, utmMedium, utmCampaign, utmTerm, utmContent, lastUpdatedTime} = utmParams;

    if (!lastUpdatedTime) {
      return {
        utmSource: "",
        utmMedium: "",
        utmCampaign: "",
        utmTerm: "",
        utmContent: "",
      };
    }

    // check utm expired time for one month
    // @see https://www.optimizesmart.com/understanding-sessions-campaigns-timeout-settings-google-analytics/
    const now = new Date().getTime();
    const sevenDaysMSecond = 7 * 24 * 60 * 60 * 1000;
    if (now - lastUpdatedTime <= sevenDaysMSecond) {
      return {
        utmSource: Array.isArray(utmSource) ? utmSource[0] : (utmSource && utmSource.slice(0, 50)) || "",
        utmMedium: Array.isArray(utmMedium) ? utmMedium[0] : (utmMedium && utmMedium.slice(0, 50)) || "",
        utmCampaign: Array.isArray(utmCampaign) ? utmCampaign[0] : (utmCampaign && utmCampaign.slice(0, 50)) || "",
        utmTerm: Array.isArray(utmTerm) ? utmTerm[0] : (utmTerm && utmTerm.slice(0, 50)) || "",
        utmContent: Array.isArray(utmContent) ? utmContent[0] : (utmContent && utmContent.slice(0, 50)) || "",
      };
    }
  } catch (error) {
    captureSentryException(error);
  }
  return {
    utmSource: "",
    utmMedium: "",
    utmCampaign: "",
    utmTerm: "",
    utmContent: "",
  };
}
