import {mediaQueryMdScreen} from "@shared/configs";
import React from "react";
import Media from "react-media";
import ErrorBoundary from "../ErrorBoundary";
import DesktopFooter from "../Footer/DesktopFooter";
import Message from "../Message/index";
import Navigation from "../Navigation";
import PromotionBar from "../PromotionBar/index";

// this is the basic structure of all pages
export default function Page({children}) {
  return (
    <ErrorBoundary>
      <PromotionBar />
      {children}
      <Media query={mediaQueryMdScreen}>{(matches) => (matches ? <DesktopFooter /> : null)}</Media>
      <Media query={mediaQueryMdScreen}>{(matches) => (matches ? null : <Navigation />)}</Media>
      <Message />
    </ErrorBoundary>
  );
}
