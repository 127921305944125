export function checkFinalStore() {
  // check if url contains storeName
  let finalStore = "default";
  const isPathContainsStore = window.location.pathname.match(
    /morningshop|default|proteinshop/
  );
  if (isPathContainsStore) {
    finalStore = isPathContainsStore[0];
    return finalStore;
  } else {
    if (window.sessionStorage.getItem("ur-final-store")) {
      finalStore = window.sessionStorage.getItem("ur-final-store")
        ? window.sessionStorage.getItem("ur-final-store")
        : "default";

      if (
        finalStore !== "default" &&
        finalStore !== "proteinshop" &&
        finalStore !== "morningshop"
      ) {
        finalStore = "default";
      }
    }
  }
  return finalStore;
}
