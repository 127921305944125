import React from 'react';
import fb_messager from '../../assets/fb-messager.svg';
import fb_messager_white from '../../assets/fb-messager_white.svg';

function MessagerIcon(props) {
    const fb = props.color === 'white' ? fb_messager_white : fb_messager;
    return (
        <a href='https://m.me/urmart123' className={props.className} target='_blank' rel="noopener noreferrer">
            <img src={fb} alt='客服' />
        </a>
    );
}

export default MessagerIcon;