import {addItem, deleteItems} from "@actions/wishlist";
import {transformCount} from "@shared/numberFormatter";
import React from "react";
import {connect} from "react-redux";
import iterableAnalytics from "@shared/iterableAnalytics";
import customHistory from "../customHistory";
import FBPixel from "../FBPixel/index";

class WishlistButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      shouldUpdate: false,
    };
  }

  shouldComponentUpdate(nextProps, nextState) {
    const {productWishCount, product} = this.props;
    const {id: productId} = product;
    const {shouldUpdate} = this.state;
    if (nextState.shouldUpdate !== shouldUpdate && nextState.shouldUpdate) {
      return true;
    }
    if (nextProps.productWishCount && productWishCount && nextProps.productWishCount[productId] !== productWishCount[productId]) {
      return true;
    }
    return false;
  }

  addToWishlist = (event) => {
    event.preventDefault();
    const {addItemToWishlist, product, productWishCount, ratingScore, mainCategory, subCategory, review, token} = this.props;
    const {id: productId, name: productName} = product;

    if (token) {
      FBPixel.addToWishlist();

      // iterable
      const count = productWishCount ? transformCount(productWishCount[parseInt(productId, 10)]) : 0;
      const productInfo = {
        ...product,
        mainCategory,
        subCategory,
        ratingScore,
        review,
        wishCount: count,
      };
      iterableAnalytics.addToWishList(productInfo);

      addItemToWishlist({
        id: productId,
        name: productName,
      });
      this.setState({
        shouldUpdate: true,
      });
    } else {
      this.navigateToLogin();
    }
  };

  deleteFromWishlist = (event) => {
    event.preventDefault();
    const {deleteWishlist, product} = this.props;
    const {id: productId} = product;
    deleteWishlist({
      id: productId,
    });
    this.setState({
      shouldUpdate: true,
    });
  };

  navigateToLogin = () => {
    customHistory.push("/customer/login");
  };

  render() {
    const {token, isUpdating, product, wishlistIds, type, productWishCount} = this.props;
    const {id: productId} = product;

    const isAdded = wishlistIds ? wishlistIds.indexOf(parseInt(productId, 10)) !== -1 : false;

    const count = productWishCount ? transformCount(productWishCount[parseInt(productId, 10)]) : 0;

    if (type === "productPage") {
      return (
        <button onClick={isAdded ? this.deleteFromWishlist : this.addToWishlist} className="ur-wishlist-button__page">
          <span className="ur-wishlist-button" disabled={isUpdating} role="presentation">
            {isAdded ? <div className="ur-wishlist-button__added" /> : <div className="ur-wishlist-button__unadded" />}
          </span>
          <span className="ur-wishlist-button__count">{isAdded ? `已收藏 (${count})` : `加入收藏 (${count})`}</span>
        </button>
      );
    }

    if (type === "productCard") {
      return (
        <div
          className="ur-wishlist-button"
          onClick={isAdded ? this.deleteFromWishlist : this.addToWishlist}
          disabled={isUpdating}
          role="presentation"
        >
          {isAdded ? <div className="ur-wishlist-button__added" /> : <div className="ur-wishlist-button__unadded" />}
          <span className="ur-wishlist-button__count">{count}</span>
        </div>
      );
    }

    return (
      <div className="ur-wishlist-button" onClick={isAdded ? this.deleteFromWishlist : this.addToWishlist} disabled={isUpdating} role="presentation">
        <div className="ur-wishlist-button__added" />
        <span className="ur-wishlist-button__count">{count}</span>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    token: state.auth.token,
    isUpdating: state.wishlist.isUpdating,
    wishlistIds: state.wishlist.byIds,
    productWishCount: state.wishlist.productWishCount,
    mainCategory: state.breadcrumb.storeName,
    subCategory: state.breadcrumb.categoryName,
    review: state.review,
    wishlist: state.wishlist,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    addItemToWishlist: (item) => {
      dispatch(addItem(item));
    },
    deleteWishlist: (item) => {
      dispatch(deleteItems(item));
    },
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(WishlistButton);
