import {AppRouter} from "@shared/AppRouter";
import {couponHelpPageLink} from "@shared/configs";
import {bool, func, string} from "prop-types";
import React from "react";

const defaultClickHander = () => AppRouter.getInstance().goBack();

export default function CouponHeader(props) {
  const {title, showHelpLink, onClickHandler} = props;

  return (
    <>
      <div className="ur-page-header__icon left" onClick={onClickHandler || defaultClickHander} role="presentation">
        <i className="material-icons">navigate_before</i>
      </div>
      <h1 className="ur-page-header__title col-xs-12">
        {title}
        {showHelpLink && (
          <a href={couponHelpPageLink} className="ur-page-header__icon right" target="_blank" rel="noopener noreferrer">
            <i className="material-icons">help_outline</i>
          </a>
        )}
      </h1>
    </>
  );
}

CouponHeader.propTypes = {
  showHelpLink: bool,
  title: string,
  onClickHandler: func,
};
CouponHeader.defaultProps = {
  showHelpLink: true,
  title: "",
  onClickHandler: defaultClickHander,
};
