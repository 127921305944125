import GA from "./GA";

export default function addPromotionEventListener() {
  document.body.addEventListener("click", (event) => {
    const {target} = event;
    if (target && target.getAttribute("data-promotion") === "true") {
      const type = target.getAttribute("data-type");
      const info = target.getAttribute("data-info");
      const position = target.getAttribute("data-position");
      const storeName = target.getAttribute("data-storename");
      const suffix = target.getAttribute("data-suffix");

      GA.clickPromotion({
        id: `${storeName}_${type}_${info}`,
        name: `${storeName}_${type}_${info}`,
        position,
      });

      GA.sendEvent(`${storeName}_${suffix}`, "click", `banner[${position}]_${type}_${info}`);
    }
  });
}
