// @flow
import {setFinalStore} from "@actions/appConfig";
import React from "react";
import Media from "react-media";
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import DesktopPageHeader from "./DesktopPageHeader";
import MobileHeader from "./MobileHeader";

type Props = {
  match: Object,
  history: Object,
  type: string,
  title: string,
  setFinalStoreAction: (storeName: string) => void,
  clickHandler?: () => void,
  isChekoutPage: boolean,
};

class PageHeader extends React.PureComponent<Props, State> {
  static defaultProps = {
    clickHandler: () => {},
  };

  componentDidUpdate(prevProps) {
    const {match, setFinalStoreAction} = this.props;
    const finalStore = match.params.storeName;

    if (prevProps.match.params.storeName !== finalStore) {
      if (finalStore !== "default" && finalStore !== "proteinshop" && finalStore !== "morningshop") {
        if (window.sessionStorage) {
          window.sessionStorage.setItem("ur-final-store", "default");
        }
        setFinalStoreAction("default");
      } else {
        if (window.sessionStorage) {
          window.sessionStorage.setItem("ur-final-store", finalStore);
        }
        setFinalStoreAction(finalStore);
      }
      setFinalStoreAction(finalStore);
    }
  }

  render() {
    const {title, type, match, history, clickHandler, isChekoutPage} = this.props;

    return (
      <React.Fragment>
        <Media query="(max-width: 991px)">
          {(isMatches) =>
            isMatches ? (
              <MobileHeader match={match} history={history} type={type} title={title} clickHandler={clickHandler} />
            ) : (
              <DesktopPageHeader storeName={match.params.storeName} history={history} isChekoutPage={isChekoutPage} />
            )
          }
        </Media>
      </React.Fragment>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    setFinalStoreAction: (finalStore) => {
      dispatch(setFinalStore(finalStore));
    },
  };
};

export default withRouter(
  connect(
    undefined,
    mapDispatchToProps,
  )(PageHeader),
);
