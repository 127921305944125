import styled from "styled-components";

const ChartBadge = styled.div`
  position: absolute;
  top: -5px;
  right: 9px;
  font-size: 10px;
  text-align: center;
  color: white;
  background-color: red;
  width: 15px;
  height: 15px;
  padding: 2px;
  border-radius: 50%;
  border: 2px solid white;
  line-height: 14px;
`;

export default ChartBadge;
