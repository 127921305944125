import React from "react";
import styled from "styled-components";
import vipLimitedIcon from "../../assets/vip_limited_icon.png";

const ImageWrapper = styled.img`
  width: 100%;
  with: 40%;
  max-width: 70px;
`;

export default function VIPLimitedTag() {
  return <ImageWrapper src={vipLimitedIcon} alt="VIPLimited" />;
}
