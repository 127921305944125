import React from "react";
import {NavLink} from "react-router-dom";
import styled from "styled-components";
import fb_messager from "../../assets/fb-messager.svg";
import {liveHelpLink} from "../../shared/configs";
import info from "./Info.json";

const LinkWrapper = styled.a`
  display: flex;
  width: 40px;
  height: 40px;
`;
export default function DesktopFooter() {
  if (window.location.pathname.indexOf("checkout") !== -1) {
    return null;
  }
  return (
    <footer id="desktop-footer" className="ur-footer">
      <div className="ur-footer__content">
        <section className="ur-footer__logo">UrMart</section>
        <section className="row middle-sm ur-footer__logo__content">
          <div className="col-xs-5">
            {info.companyInfo.copyrights}
            {info.companyInfo.law.map((sentence, index) => (
              <p key={index}>{sentence}</p>
            ))}
          </div>
          <div className="col-xs-5">
            <NavLink to="/default/about" activeClassName="ur-footer__link-active" style={{borderLeft: "1px solid #333333"}}>
              &nbsp;&nbsp;商店資訊&nbsp;&nbsp;
            </NavLink>
            <NavLink to="/default/privacy-policy" activeClassName="ur-footer__link-active" style={{borderLeft: "1px solid #333333"}}>
              &nbsp;&nbsp;隱私權條款&nbsp;&nbsp;
            </NavLink>
            <NavLink to="/default/member-rights" activeClassName="ur-footer__link-active" style={{borderLeft: "1px solid #333333"}}>
              &nbsp;&nbsp;會員權益&nbsp;&nbsp;
            </NavLink>
            <NavLink to="/default/customer-service" activeClassName="ur-footer__link-active" style={{borderLeft: "1px solid #333333"}}>
              &nbsp;&nbsp;客服資訊&nbsp;&nbsp;
            </NavLink>
            <a
              href={liveHelpLink}
              target="_blank"
              rel="noopener noreferrer"
              style={{borderLeft: "1px solid #333333", borderRight: "1px solid #333333"}}
            >
              &nbsp;&nbsp;常見問題&nbsp;&nbsp;
            </a>
          </div>
          <div className="col-xs-2 row end-xs">
            <LinkWrapper className="col-xs-4" href="https://m.me/urmart123" target="_blank">
              <img src={fb_messager} alt="fb客服" />
            </LinkWrapper>
          </div>
        </section>
      </div>
    </footer>
  );
}
