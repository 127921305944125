import {s3Assets} from "./configs";

export const urmartIcon = `${s3Assets}/defaultIcon-urmart.png`;
export const morningshopIcon = `${s3Assets}/defaultIcon-morningshop.png`;
export const proteinshopIcon = `${s3Assets}/defaultIcon-proteinshop.png`;
export const urmartLogo = `${s3Assets}/desktop/UrMart-desktop.png`;
export const proteinshopLogo = `${s3Assets}/desktop/PS-desktop.png`;
export const morningshopLogo = `${s3Assets}/desktop/MS-desktop.png`;
export const linepayIcon = `${s3Assets}/linepay_small.png`;
export const discountPng = `${s3Assets}/discount.png`;
export const checkoutArrow = `${s3Assets}/checkout-arrow.png`;
