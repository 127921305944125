import API from "@shared/api";
import {formatProductList} from "@shared/productHelpers";
import {bool, number} from "prop-types";
import React from "react";
import {connect} from "react-redux";
import HorizantalScrollMenu from "../../HorizantalScrollMenu/HorizatalScrollMenu";

class HotSaleList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      products: [],
    };
    this.api = null;
  }

  componentDidMount() {
    const {hotSaleId} = this.props;
    if (hotSaleId) {
      this.api = new API(`/category/products?categoryId=${hotSaleId}&currentPage=1&size=10&sort=bestseller&direc=desc`, "default");
      this.api
        .getData()
        .then((res) => {
          this.setState({
            products: formatProductList(res.data.products),
          });
        })
        .catch((error) => {});
    }
  }

  componentDidUpdate() {
    const {hotSaleId} = this.props;
    const {products} = this.state;
    if (products.length === 0 && hotSaleId) {
      this.api = new API(`/category/products?categoryId=${hotSaleId}&currentPage=1&size=10&sort=bestseller&direc=desc`, "default");
      this.api
        .getData()
        .then((res) => {
          this.setState({
            products: formatProductList(res.data.products),
          });
        })
        .catch((error) => {});
    }
  }

  componentWillUnmount() {
    if (this.api) {
      this.api.cancelRequest();
    }
  }

  render() {
    const {products} = this.state;
    const {adultContentPermission} = this.props;
    const productIds = products.map((element) => element.id);

    if (products.length > 0) {
      return (
        <div className="ur-section ur-background-grey">
          <h6 className="ur-section__title ur-text-level-3" style={{fontWeight: 400, padding: "10px 0 0 10px"}}>
            熱銷好評
          </h6>
          <HorizantalScrollMenu
            isLimited={false}
            storeName="default"
            products={products}
            productIds={productIds}
            categoryName="熱銷好評"
            list="Customer"
            title="熱銷好評"
            adultContentPermission={adultContentPermission}
          />
        </div>
      );
    }
    return null;
  }
}

HotSaleList.propTypes = {
  adultContentPermission: bool.isRequired,
  hotSaleId: number.isRequired,
};

const mapStateToProps = (state) => {
  return {
    hotSaleId: state.appConfig.hot_sale_id,
    adultContentPermission: state.adultContent.permission,
  };
};

export default connect(mapStateToProps)(HotSaleList);
