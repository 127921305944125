import {sendComponentError} from "@shared/setupSentry";
import React from "react";

class ErrorBoundary extends React.Component {
  static getDerivedStateFromError(error) {
    return {error};
  }

  constructor(props) {
    super(props);
    this.state = {errorInfo: null};
  }

  componentDidCatch(error, errorInfo) {
    // rerender error component
    this.setState({
      errorInfo,
    });

    sendComponentError(error.toString(), errorInfo.componentStack);
  }

  render() {
    const {children, errorComponent} = this.props;
    const {errorInfo} = this.state;

    if (errorInfo && errorComponent) {
      return errorComponent;
    }

    if (errorInfo && !errorComponent) {
      return <details>發生不明錯誤</details>;
    }

    return children;
  }
}

export default ErrorBoundary;
